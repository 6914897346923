import React, { Component } from 'react';
import axios from 'axios';

class SamlConsume extends Component {
  constructor(props) {
    super(props);
    this.state = { loggedIn: false, samlError: false };
  }

  setAttributes = () => {
    let self = this;
    axios.get("saml/attributes").then((response) => {
      console.log("SAML Response")
      console.log(response);
      console.log("DATA")
      console.log(response.data);
      let name;
      if (response.data.display_name) {
        name = response.data.display_name.split(" ");
      } else {
        name = response.data.name.split(" ");
      }
      localStorage.setItem("firstName", name[0]);
      localStorage.setItem("lastName", name[name.length - 1]);
      localStorage.setItem("email", response.data.eppn);
      // localStorage.setItem("affiliation", response.data.affiliaton[0]); //don't think we actually care
      localStorage.setItem("netid", response.data.netid);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("group", response.data.group);
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("actAs", response.data.actAs);
      localStorage.setItem("displayName", response.data.display_name);



      self.setState({ loggedIn: true });
    }).catch((error) => {
      console.log(error)
    });
  }

  returnToHomePage = () => {
    this.setAttributes();
    if(this.state.loggedIn){
      window.location.href='/';
    }else if(this.state.samlError){
      return <div>
        <h2>Authentication Error</h2>
          <p>There was an error logging you in. This is usually either an issue with your system clock, or something unforseen on our end.</p>
      </div>
    }
  
}


  // componentWillUnmount() {
  //   this.setState = (state,callback)=>{
  //     return;
  //   };
  // }

  render() {
    return (<div>
      {this.returnToHomePage()}
    </div>);
  }
}

export default SamlConsume;