import React, { Component } from "react";
import axios from 'axios';
import Flash from '../common/flash';
import "./groupIndex.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Modal from "./modal";
import TextReminder from "./textReminder";

export default class GroupIndex extends Component {

    state = {
        ipGroup: false, locations: [], ips: [], modalType: false,
        weeklyReminder: {}, dailyReminder: {}, hourlyReminder: {},
        timecardReminder: {}, waitOnQueue: false
    }

    componentDidMount() {
        let group = localStorage.getItem('group').split(",")[0];
        this.setGroupData(group);
        this.setState({ ipGroup: group })
    }

    //get group data
    getGroupData = (group) => {
        return axios.get("workGroups/find/" + group);
    }

    setGroupData = async (value) => {
        let response = await this.getGroupData(value);
        if (response.status !== 200) {
            console.log("could not find group");
        } else if (response.data) {
            let data = response.data;
            // console.log(data);
            let newIps = [];
            if (data.ips) {
                newIps = data.ips;
            } else if (data.ipRange) {
                newIps.push({ ipFrom: data.ipFrom, ipTo: data.ipTo });
            }
            this.setState({
                grouperPath: data.grouperPath, teamsUrl: data.teamsUrl,
                requireCover: data.requireCover, locations: data.locations,
                ips: newIps, maxShiftHours: data.maxShiftHours,
                weeklyReminder: data.weeklyReminder || {}, dailyReminder: data.dailyReminder || {},
                hourlyReminder: data.hourlyReminder || {}, timecardReminder: data.timecardReminder || {}
            });
        }
    }

    //get group option from the local storage
    groupOptions = () => {
        var options = []
        let groups = localStorage.getItem('group').split(",");
        groups.forEach((group) =>
            options.push(
                <option key={group} className="capitalize-me" value={group}>{group}</option>
            )
        )
        return options
    }

    // generic change handler
    changeHandler = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        let id = event.target.id;
        if (name === "ipGroup") {
            this.setGroupData(value);
            this.setState({ [name]: value });
        } else if (name === "ipFrom" || name === "ipTo") {
            let newIPs = this.state.ips;
            if (!newIPs[id]) { newIPs[id] = {} }
            newIPs[id][name] = value;
            this.setState({ ips: newIPs });
        } else if (name === "locations") {
            let newLocations = this.state.locations;
            newLocations[id] = value;
            this.setState({ locations: newLocations });
        } else {
            this.setState({ [name]: value });
        }
    }

    groupSelect = () => {
        return <div className="field">
            <div className="control">
                <label className="label">Group:</label>
                <div className="select">
                    <select value={this.state.ipGroup} name="ipGroup" onChange={this.changeHandler}>
                        {this.groupOptions()}
                    </select>
                </div>
            </div>
        </div>
    }

    setIPRange = () => {
        if (this.state.ipGroup) {
            let exisitingIps = [];
            if (this.state.ips.length > 0) {
                this.state.ips.map((ip, index) => exisitingIps.push(this.eachIPRange(ip, index)));
            }
            exisitingIps.push(this.eachIPRange({}, exisitingIps.length));
            return <div className="field">
                <h2 className="title is-5">IP Ranges
                    <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.setState({ modalType: 'IP' })} /></h2>
                {exisitingIps}
            </div>
        }
    }

    endIP = (index) => {
        let newIPs = this.state.ips;
        newIPs.splice(index, 1);
        this.setState({ ips: newIPs });
        this.updateGroup({ ips: newIPs });
    }

    eachIPRange = (ip, index) => {
        let cancelIPRange;
        if (ip.ipFrom || ip.ipTo) {
            cancelIPRange =
                <div className="control">
                    <label className="label">Action:</label>
                    <button className="button" type="button" onClick={() => this.endIP(index)}>End Range</button>
                </div>
        }
        return <div className="field is-grouped" key={index}>
            <div className="control">
                <label className="label">From:</label>
                <input className="input" type="text" name="ipFrom" id={index} value={ip.ipFrom ? ip.ipFrom : ""} onChange={this.changeHandler} />
            </div>
            <div className="control">
                <label className="label">To:</label>
                <input className="input" type="text" name="ipTo" id={index} value={ip.ipTo ? ip.ipTo : ""} onChange={this.changeHandler} />
            </div>
            <div className="control">
                <label className="label">Action:</label>
                <button className="button" type="button" onClick={() => this.updateGroup({ ips: this.state.ips })}>Set Range</button>
            </div>
            {cancelIPRange}
            <br />
        </div>
    }

    setGrouperPath = () => {
        if (this.state.ipGroup) {
            return <div className="field is-grouped">
                <div className="control">
                    <label className="label">Grouper Path:
                        <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.setState({ modalType: 'grouper' })} /></label>
                    <input className="input long-input" type="text" name="grouperPath" value={this.state.grouperPath ? this.state.grouperPath : ""} onChange={this.changeHandler} />
                </div>
                <div className="control">
                    <label className="label">Action:</label>
                    <button className="button" type="button" onClick={() => this.updateGroup({ grouperPath: this.state.grouperPath })}>Set Path</button>
                </div>
                <br />
            </div>
        }
    }

    setTeamsURL = () => {
        if (this.state.ipGroup) {
            return <div className="field is-grouped">
                <div className="control">
                    <label className="label">Teams URL:
                        <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.setState({ modalType: 'teamsURL' })} /></label>
                    <input className="input long-input" type="text" name="teamsUrl" value={this.state.teamsUrl ? this.state.teamsUrl : ""} onChange={this.changeHandler} />
                </div>
                <div className="control">
                    <label className="label">Action:</label>
                    <button className="button" type="button" onClick={() => this.updateGroup({ teamsUrl: this.state.teamsUrl })}>Set URL</button>
                </div>
                <br />
            </div>
        }
    }

    setMaxHours = () => {
        if (this.state.ipGroup) {
            return <div className="field is-grouped">
                <div className="control">
                    <label className="label">Weekly Hour Limit:
                        <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.setState({ modalType: 'weeklyLimit' })} /></label>
                    <input className="input long-input" type="number" min={0} name="maxShiftHours" value={this.state.maxShiftHours ? this.state.maxShiftHours : ''} onChange={this.changeHandler} />
                </div>
                <div className="control">
                    <label className="label">Action:</label>
                    <button className="button" type="button" onClick={() => this.updateGroup({ maxShiftHours: this.state.maxShiftHours })}>Set Hours</button>
                </div>
                <br />
            </div>
        }
    }

    setModalType = (type) => {
        this.setState({ modalType: type });
    }

    requireShiftCoverage = () => {
        if (this.state.ipGroup) {
            return <div className="field is-grouped">
                <div className="control">
                    <label className="label">Shift Coverage:
                        <FontAwesomeIcon className="clickable" icon={faQuestionCircle} onClick={() => this.setModalType('shiftCoverage')} /></label>
                    <label className="checkbox">
                        <input type="checkbox" name="requireCover" defaultChecked={this.state.requireCover} onChange={(e) => this.setState({ requireCover: e.target.checked })} />
                        Require shift cover
                    </label>
                </div>
                <div className="control">
                    <label className="label">Action:</label>
                    <button className="button" type="button" onClick={() => this.updateGroup({ requireCover: this.state.requireCover })}>Set Coverage</button>
                </div>
                <br />
            </div>
        }
    }

    addNewLocation = () => {
        this.setState(prevState => ({ locations: prevState.locations.concat('') }))
    }

    //delete the last location input field
    removeLocationField = () => {
        this.state.locations.pop()
        this.setState(prevState => ({
            myArray: [prevState.locations]
        }))
    }

    setGroupLocation = () => {
        if (this.state.ipGroup) {
            return <div className="field">
                <div className="control">
                    <label className="label">Group Locations:
                        <FontAwesomeIcon className="clickable" icon={faPlusCircle} onClick={this.addNewLocation} />
                        <FontAwesomeIcon className="clickable" icon={faMinusCircle} onClick={this.removeLocationField} />
                    </label>
                    {this.state.locations.map((location, index) => <input className="input short-input" key={index} type="text" name="locations" id={index} value={location} onChange={this.changeHandler} />)}
                </div>
                <div className="control">
                    <label className="label">Action:</label>
                    <button className="button" type="button" onClick={() => this.updateGroup({ locations: this.state.locations })}>Set Locations</button>
                </div>
            </div>
        }
    }

    updateMessageSchedule = (body) => {
        let group = this.state.ipGroup;
        axios.put(`workGroups/update/${group}`, body).then(response => {
            this.setState({ success: [`Your group has been updated ${body.name}.`], error: false });
            this.setState({waitOnQueue: true});
            axios.get(`messageScheduler/clearAndSetQueue/${group}`).then(response => {
                console.log("response", response);
                this.setState({waitOnQueue: false});
            }).catch((error) => {
                console.log(error)
            });
        }).catch((error) => {
            console.log(error)
        });
    }

    updateGroup = (body) => {
        if (body.ips) {
            body.ipFrom = null;
            body.ipTo = null;
            body.ipRange = null;
        }
        axios.put(`workGroups/update/${this.state.ipGroup}`, body).then(response => {
            this.setState({ success: [`Your group has been updated ${body.name}.`], error: false });
        }).catch((error) => {
            console.log(error)
        });
    }

    setFlash = (error, success) => {
        this.setState({ error: error, success: success });
    };

    //show error/success message
    flash = () => {
        let ret = [];

        if (this.state.success) {
            ret.push(<Flash key="success" type="success" title="Success" messages={["Your edit has been saved!"]} closeFlash={() => this.setState({ success: false })} />);
        }

        if (this.state.error) {
            let errTitle = "Incomplete Form";
            let errMessage = this.state.error.toString();

            //check for systen error
            if (errMessage.slice(0, 5) === "SYS!:") {
                errTitle = "System Error";
                errMessage = errMessage.slice(5, errMessage.length);
            }

            ret.push(
                <Flash key="error" type="error" title={errTitle} messages={[errMessage]} closeFlash={() => this.setState({ error: false })} />
            );

        }

        return ret;
    };

    clockModal = () => {
        this.setState({ modalType: false })
    }

    render() {
        return <div className="container">
            {this.flash()}
            <Modal type={this.state.modalType} clockModal={this.clockModal} />
            <h1 className="title my-5">Group Management</h1>
            {this.groupSelect()}
            <TextReminder weeklyReminder={this.state.weeklyReminder} dailyReminder={this.state.dailyReminder}
                hourlyReminder={this.state.hourlyReminder} timecardReminder={this.state.timecardReminder}
                updateGroup={this.updateMessageSchedule} 
                waitOnQueue={this.state.waitOnQueue} setModalType={this.setModalType}/>
            {this.setIPRange()}
            {this.setMaxHours()}
            {this.setGrouperPath()}
            {this.setTeamsURL()}
            {this.requireShiftCoverage()}
            {this.setGroupLocation()}
        </div>
    }
}