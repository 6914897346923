import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { clearGlobalFlash, writeGlobalFlash } from './helper';


// TODO consider doing something like this to handle global host url
// in addition to adding auth to all you requests by default
// axios.defaults.baseURL = process.env.REACT_APP_DISCOVER_API_HOST
// axios.defaults.headers.common['Authorization'] = bearerToken();
import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_EXPRESS_HOST;
axios.defaults.timeout = 120000; //2 minute global time out, yes this is long, but many times the matching can take longer. 

//interceptors for global response code handle
axios.interceptors.response.use(
	(response) => {
		//anything global we want for all successfull/200 range codes here
		clearGlobalFlash();
		return response;
	},
	(err) => {
		clearGlobalFlash();
		if (err.response?.status === 401) {
			//not authenticated
			localStorage.clear();
			window.location.href = '/'
		} else if (err.response?.status === 403) {
			//not authorized
			let errHeader = 'NOT AUTHORIZED';
			let errMsg = 'You are not authorized to take this action.';
			writeGlobalFlash(errHeader, errMsg);
		}
		return Promise.reject(err);
	}
);


ReactDOM.render(
	(<React.StrictMode>
		<App />
	</React.StrictMode>),
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
