import React, { Component } from 'react';
import axios from 'axios';



class SplitShiftModal extends Component {
  constructor(props) {
    super(props);
    this.state = { dividerTime: null }
  }

  componentDidMount() {
  }

  formModal = () => {
    if (this.props.modal) {
      let startTime =  new Date(this.props.shift.start_time*1000)
      let endTime = new Date(this.props.shift.end_time*1000)

      startTime = `${startTime.getHours()}:${startTime.getMinutes() === '0' ? '00' :  startTime.getMinutes()}`
      endTime = `${endTime.getHours()}:${endTime.getMinutes() === '0' ? '00': endTime.getMinutes()}`

      return (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Set New Shift Times</p>
              <button onClick={this.props.onClose} className="delete" aria-label="close"></button>
            </header>
            <section className="modal-card-body">
              <form>
                <div className="field">
                <label className="label">Choose Time to Split Shift: </label>
                  <div className="control">
                    <input className="input" name="dividerTime" type="time" value={this.state.dividerTime ? this.state.dividerTime : ''} 
                    onChange={this.changeHandler} />
                  </div>
                </div>
              </form>
              <p>Shift 1: {startTime} - {this.state.dividerTime}</p>
              <p>Shift 2: {this.state.dividerTime} - {endTime}</p>
            </section>
            <footer className="modal-card-foot">
              <div className="control">
                <input className="button is-dark" type='submit' onClick={this.submitForm} />
              </div>
            </footer>
          </div>
        </div>
      );
    }
  }


  groupOptions = () => {
    var groups = localStorage.getItem('group').split(",");
    var options = [];
    groups.forEach((group, index) =>
      options.push(
        <label key={index} className="radio mr-5">
          <input name='group' value={group} type="radio" onChange={this.changeHandler} />
          &nbsp;{group}
        </label>
      )
    );
    return options;
  }

  
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
    if (name === 'group') {
      this.getGroupLocations(value);
      this.getEmployeeList();
    } else if (name === 'employee') {
      this.getEmployee(value);
    }
  }


  submitForm = (event) => {
    event.preventDefault();
    //verify that user provided a value
    if(!this.state.dividerTime){return alert("Please Select a Valid Time")}
    
    //set the new divider time from the form
    let dividerEpoch = new Date(parseInt(this.props.shift.start_time * 1000 ));
    const splitTime = this.state.dividerTime.split(":");
    dividerEpoch.setHours(parseInt(splitTime[0]))
    dividerEpoch.setMinutes(parseInt(splitTime[1]))

    dividerEpoch = dividerEpoch.getTime()

    let startTime =  new Date(parseInt(this.props.shift.start_time *1000))
    let endTime = new Date(parseInt(this.props.shift.end_time *1000))

    //verify the selected time is after the start and before the end
    if( (dividerEpoch < startTime) || (dividerEpoch > endTime)){return alert("Please Select a Valid Time")}



    const body = {shiftId: this.props.shift.shiftId, dividerEpoch: dividerEpoch / 1000}
      axios.post("shifts/split/", body).then((response) => {
        this.props.refreshShifts();
        this.props.onClose();
      }).catch(function (err) {
        console.log(err)
      });
    
  }

  render() {
    return (
      <div>
        {this.formModal()}
      </div>
    );
  }
}

export default SplitShiftModal