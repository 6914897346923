import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

export default class HoverInfo extends React.Component {
    constructor(props) {
        super();
        this.state = { anchorEl: null, open: false, id: null }
    }

    handleClick = (event) => {
        let anchorEl = event.currentTarget;
        let open = Boolean(anchorEl);
        this.setState({anchorEl: event.currentTarget, open: Boolean(anchorEl), id: open ?  'simple-popover' : undefined})
    }
    handleClose = () => {
        let anchorEl = null;
        let open = Boolean(anchorEl);
        this.setState({anchorEl: null, open: Boolean(anchorEl), id: open ?  'simple-popover' : undefined})
    }
    render() {
        return (
            <div style={{display: 'inline-block', padding: '2px'}}>
                <div className="dropdown is-hoverable">
                    <div className="dropdown-trigger">
                        <FontAwesomeIcon className="clickable" icon={faInfoCircle} onClick={this.handleClick}/>
                    </div>
                    <div className="dropdown-menu" id="dropdown-menu4" role="menu">
                        <div className="dropdown-content">
                            <div className="dropdown-item" style={{width: '300px', fontSize: '12px'}}>
                                <p>{this.props.message}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}