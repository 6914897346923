import React, { Component } from 'react'
import AvailabilityModal from './availabilityModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faCheckSquare, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faSquare } from '@fortawesome/free-regular-svg-icons'


class ShowAvailability extends Component {
  constructor(props) {
    super()
    this.state = { role: false, availabilityModal: false, adhoc: false }
  }

  componentDidMount() {
    this.setAdHoc();
  }

  componentDidUpdate(prevProps) {
    let changedProps = prevProps.adhoc !== this.props.adhoc
    if (changedProps) {
      console.log("set adhoc")
      this.setAdHoc();
    }
  }

  setAdHoc = () => {
    if (this.props.adhoc && this.props.adhoc.length > 0 && this.props.adhoc.includes(this.props.user.netid)) {
      this.setState({ adhoc: true });
    } else {
      this.setState({ adhoc: false });
    }
  }

  availabilityModal = () => {
    if (this.state.availabilityModal) {
      return <AvailabilityModal closeAvalability={this.closeAvalability} user={this.props.user} />
    }
  }

  closeAvalability = () => {
    this.setState({ availabilityModal: false })
  }

  openAvalability = () => {
    this.setState({ availabilityModal: true })
  }

  adhocTime = () => {
    let adhoc = this.props.adhoc ? this.props.adhoc : [];
    console.log("before ", this.props.adhoc);
    let employee = this.props.user.netid;
    if (adhoc && adhoc.includes(employee)) {
      let index = adhoc.findIndex(user => user === employee)
      adhoc.splice(index, 1)
    }
    else {
      adhoc.push(employee)
    }
    console.log("after ", adhoc);
    this.setState({ adhoc: !this.state.adhoc }, () => {
      this.props.updateAdHoc(adhoc);
    })
  }

  showAvailability = () => {
    return <div className="table-icons">
      <span className={this.state.adhoc ? "table-icon blue clickable" : "table-icon clickable"} onClick={this.adhocTime.bind(this)}>
        Self Report
        <FontAwesomeIcon className="spacer" icon={this.state.adhoc ? faCheckSquare : faSquare} />
      </span>

      <span className="table-icon tooltip" onClick={this.openAvalability.bind(this)}>
        <FontAwesomeIcon className="clickable" icon={faCalendar} />
        <span className="tooltiptext">Availability</span>
      </span>

      {this.props.user.phoneAddress ? <span className="table-icon tooltip">
        <FontAwesomeIcon icon={faPhone} />
        <span className="tooltiptext">Opt for Text Reminder</span>
      </span> : ''}
    </div>
  }

  render() {
    return (
      <div>
        {this.showAvailability()}
        {this.availabilityModal()}
      </div>
    )
  }
}

export default ShowAvailability