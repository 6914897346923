import React, { Component } from 'react';
import axios from 'axios';
import format from 'date-fns/format'
import getUnixTime from 'date-fns/getUnixTime'
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    shifts: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    block: {
        display: 'block',
        marginBottom: '25px'
        // height: '100%',
    },
    table: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    subtitle: {
        fontSize: 12
    },
    cellHeader: {
        fontSize: 9,
        backgroundColor: '#3273dc',
        color: 'white',
        paddingTop: '5px',
        flex: '0 0 80px',
        border: '1px solid grey',
        height: '25px',
        textAlign: 'center',
    },
    cellBody: {
        fontSize: 10,
        flex: '0 0 80px',
        border: '0.5px solid grey',
        height: '25px',
        textAlign: 'center',
        paddingTop: '5px',
    }
});

// summary daily hours
export default class WeeklyHourTable extends Component {
    constructor(props) {
        super();
        this.state = { data: [], dates: [] }
    }

    componentDidMount() {
        this.initiateDisplay();
    }

    componentDidUpdate(prevProps) {
        let changedProps = prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate
        if (changedProps) {
            this.initiateDisplay();
        }
    }

    initiateDisplay = () => {
        this.getShifts()
        let dates = [];
        let days = (this.props.endDate - this.props.startDate) / 1000 / 3600 / 24;
        for (let i = 0; i < days; i++) {
            let newDate = new Date((this.props.startDate / 1000 + 24 * 3600 * i) * 1000);
            dates.push(format(newDate, "yyyy-MM-dd"));
        }
        this.setState({ dates: dates })
    }


    sortTime = (data) => {
        return data?.sort((a, b) => {
            if (a._id < b._id)
                return -1;
            if (a._id > b._id)
                return 1;
            return 0;
        });
    }

    getShifts = () => {
        let start = getUnixTime(new Date(this.props.startDate));
        let end = getUnixTime(new Date(this.props.endDate));
        // console.log(start, end)
        // console.log('selected employee', this.props.selectedEmployee)
        // supervisor get specific employee hours
        if (this.props.selectedEmployee) {
            axios.get(`shifts/combined_hours/${start}/${end}/${this.props.selectedEmployee.group}/${this.props.selectedEmployee.netid}`).then((response) => {
                this.sortTime(response.data);
                // console.log("shifts", response.data)
                this.setState({ data: response.data })
            }).catch((error) => {
                console.log(error)
            });
        } else {
            // employee get hours for self
            axios.get(`shifts/combined_hours/${start}/${end}/${localStorage.getItem('group')}`).then((response) => {
                this.sortTime(response.data);
                // console.log(response.data)
                this.setState({ data: response.data })
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    drawShifts = () => {
        let sizing = "";
        if (this.props.pdf) {
            // console.log("this.state.data", this.state.data)
            if (this.state.data.length > 0) {
                let datesDisplay = [];
                let hours = [];
                this.state.dates.forEach((d, index) => {
                    datesDisplay.push(d.substring(5))
                    let findDate = this.state.data.find(data => data._id === d);
                    if (findDate) {
                        hours.push(Math.round((findDate["total_clocked_hours"]) / 360) / 10)
                    } else {
                        hours.push(0)
                    }
                })
                return <><Text style={styles.subtitle}>Total Clocked Hours:</Text>
                    <View style={styles.block}>
                        <View style={styles.table}>
                            {datesDisplay.map(d => <Text style={styles.cellHeader}>{d}</Text>)}
                        </View>
                    </View>
                    <View style={styles.block}><View style={styles.table}>
                        {hours.map(h => <Text style={styles.cellBody}>{h}</Text>)}
                    </View>
                    </View></>
            } else {
                return <View><Text style={styles.shifts}>This employee did not clock hours.</Text></View>
            }
        } else {
            if (this.state.data) {
                let datesDisplay = [];
                this.state.dates.forEach((d, index) => {
                    datesDisplay.push(<th key={`${d}-${index}`}><h1 className='title is-6 table-head'>{d.substring(5)}</h1></th>)
                })
                return <div className={sizing}>
                    <table className="table is-bordered is-striped is-narrow is-fullwidth">
                        <thead>
                            <tr>
                                <th><h1 className='title is-6 table-head'>Pay Period</h1></th>
                                {datesDisplay}
                            </tr>
                        </thead>
                        <tbody>
                            {this.mapCompleteTotals()}
                        </tbody>
                    </table>
                </div>
            }
        }
    }

    getFromData = (field) => {
        // console.log(this.state.data)
        let hours = [];
        if (this.state.data) {
            this.state.dates.forEach((d, index) => {
                let findDate = this.state.data.find(data => data._id === d);
                if (findDate) {
                    hours.push(<td key={index}> {Math.round((findDate[field]) / 360) / 10}</td>)
                } else {
                    hours.push(<td key={index}>0</td>)
                }
            })
        }
        return hours;
    }

    mapCompleteTotals = () => {
        let hours = this.getFromData("total_clocked_hours");
        return <tr>
            <td>Daily Clocked Hours</td>
            {hours}
        </tr>
    }

    render() {
        return <>
            {this.drawShifts()}
        </>
    }

}