import React, { Component } from 'react';
import Banner from './banner';
import Flash from "../common/flash";

class BannerIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      scheduleDraftData: false,
      checkedList: null
    }
  }

  closeFlash = () => {
    this.setState({ error: false, success: false })
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  //show error message
  flash = () => {
    if (this.state.error) {
      return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
    }
    if (this.state.success) {
      return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
    }
  }

  render() {
    return (
      <div className="container">
        {this.flash()}
        <h1 className="title my-5">Banners</h1>
        <div className="schedule-block">
          <Banner />
        </div>
      </div>
    )
  }
}


export default BannerIndex
