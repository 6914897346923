import React, { Component } from 'react';
import axios from 'axios';

export default class ChangeRole extends Component {

  changeRoleSumbit = (event) => {
    event.preventDefault();
    if (event.target.netid.value && event.target.group.value) {
      this.props.updateFlash({ success: true, error: false })
      this.setRole({ netid: event.target.netid.value, role: event.target.group.value });
    } else {
      this.props.updateFlash({ success: false, error: ["Please complete the fields!"] })
    }
  }

  //set role for a person
  setRole = (role) => {
    axios.put("users/updateUserRole", role)
      .then()
      .catch((error) => {
        console.log(error)
      });
  }

  render() {
    return <div className="card">
      <div className="card-content">
        <h3 className="label">Change Role </h3>
        <form method="put" onSubmit={this.changeRoleSumbit}>
          <div className="field is-grouped">
            <label className="label">Netid: </label>
            <div className="control">
              <input className="input" type="text" name="netid" placeholder="Enter netid" />
            </div>
            <label className="label">Role:</label>
            <div className="control">
              <div className="select">
                <select name="group" defaultValue="none">
                  <option hidden disabled value="none"> -- select role -- </option>
                  <option value="employee">Employee</option>
                  <option value="supervisor">Supervisor</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
            </div>
            <div className="control">
              <input className="button is-info is-light " type="submit" />
            </div>
          </div>
        </form>
      </div>
    </div>
  }
}