import React, { Component } from 'react';
import axios from 'axios';
import startOfWeek from 'date-fns/startOfWeek'
import subWeeks from 'date-fns/subWeeks'
import toDate from 'date-fns/toDate';
import Table from '../common/table';

class UserActivity extends Component {
  constructor(props) {
    super();
    this.state = {
      data: null,
      start_date: subWeeks(startOfWeek(new Date()), 2),
      end_date: new Date(),
      choose_date: false,
      logs: [],
      columns: [
        { title: "Name", data: 'name' },
        { title: "Netid", data: 'netid' },
        { title: "duid", data: 'duid' },
        { title: "Role", data: 'role' },
        { title: "Access time", data: "time" },
        { title: "Reason", data: 'reason' },
        { title: "IP address", data: 'ip' },
      ]
    }
  }

  componentDidMount = () => {
    axios.get("/users/getLogs")
      .then((response) => {
        this.setState({ logs: this.updateLogs(response.data.split(";")) });
      }).catch((error) => {
        console.log(error)
      });
  }

  updateLogs = (logs) => {
    let newLogs = [];
    if (logs && logs.length > 0) {
      logs.forEach((log, index) => {
        let newLog = log.split(" - ");
        if (index !== logs.length - 1) {
          newLogs[index] = {
            name: newLog[0],
            netid: newLog[1],
            duid: newLog[2],
            role: newLog[3],
            time: newLog[4],
            reason: newLog[5],
            ip: newLog[6]
          }
        }
      });
    }
    return newLogs;
  }

  changeDateHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var date_parts = value.split("-")
    var date = toDate(new Date(date_parts[0], date_parts[1] - 1, date_parts[2], 0, 0, 0))

    this.setState({
      [name]: date
    });
  }

  chooseDate = () => {
    if (this.state.choose_date) {
      return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">

          <header className="modal-card-head">
            <p className="modal-card-title">Change Pay Period Displayed</p>
            <button onClick={this.toggleShowDatePicker.bind(this)} className="delete" aria-label="close"></button>
          </header>

          <section className="modal-card-body">
            <form>
              <div className="field">
                <label className="label">Start Date</label>
                <div className="control">
                  <input
                    className="input"
                    type="date"
                    placeholder="yyyy-mm-dd"
                    name="start_date"
                    onChange={this.changeDateHandler}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label">End Date</label>
                <div className="control">
                  <input
                    className="input"
                    type="date"
                    placeholder="yyyy-mm-dd"
                    name="end_date"
                    onChange={this.changeDateHandler}
                  />
                </div>
              </div>
            </form>
          </section>

          <footer className="modal-card-foot">
            <div className="control">
              <input className="button is-link" onClick={this.getShifts} type='submit' />
            </div>
          </footer>

        </div>
      </div>
    }
  }

  render() {
    return (
      <div className="container">
        <h1 className="title is-4">User Activities</h1>
        <Table data={this.state.logs} columns={this.state.columns}/>
      </div>
    );
  }
}

export default UserActivity