import React, { Component } from 'react';
import axios from 'axios';
import CurrentShift from "./currentShift";
import ShiftModal from "./splitShiftModal";
import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';
import getUnixTime from 'date-fns/getUnixTime';
import Flash from "../common/flash";
import OpenshiftPool from './openshiftPool';
import UpcomingShifts from './upcomingShifts';
import { checkGroups } from '../helpers/checkGroupName';
import { teamsNotify } from '../helpers/teams';
import '../../css/mobile_and_more.css';
import TakeABreakModal from './takeABreakModal';

class HomeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "", groups: null, shiftsToday: 0, shifts: [], additionalShifts: [], upcomingShifts: [], noUpcoming: null,
      openShifts: [], modal: false, breakModal: false, editShift: false, interval: null, mergeShift: false,
      error: false, success: false
      //message: ["Daylight saving is this Sunday, Nov 7th. All schedules created within the past month should be good to go. But if there're any errors, please let your supervisor and the dev team know. Thanks! --Anni"]
    };
  }

  componentDidMount = () => {
    this.getGroups();
    this.getShiftsToday();
    this.getUpcomingShifts();
    this.getOpenShifts();
    this.setState({ interval: setInterval(this.getOpenUpcomingShifts, 1000 * 60 * 2) })
    //check for correct groups (creates flash error if group name has been changed)
    checkGroups().then((response) => {
      if (response.error) { this.setFlash(("SYS!:" + response.error), false); }
    });
    this.setState({ name: localStorage.getItem("displayName")});
  }

  getOpenUpcomingShifts = () => {
    this.getOpenShifts();
    this.getUpcomingShifts();
  }

  logAction = (reason) => {
    axios.get("/users/log/" + reason)
      .then((response) => {
        console.log(response.data);
      }).catch((error) => {
        console.log(error)
      });
  }

  getGroups = () => {
    axios.get("workGroups/fetchGroups").then(response => {
      let groups = response.data;
      if (groups) {
        let index = groups.findIndex(group => group.name === "adhoc")
        groups.splice(index, 1)
      }
      this.setState({ groups: response.data })
    }).catch((error) => {
      console.log(error)
    });
  }

  getShiftsToday = () => {
    let startTime = getUnixTime(startOfToday());
    let endTime = getUnixTime(endOfToday());
    // if (localStorage.getItem('role') === 'employee') {
    axios.get("shifts/find_by_time_and_user/" + startTime + "/" + endTime).then((response) => {
      let sortedShifts = response.data;
      if (sortedShifts) {
        sortedShifts.sort((a, b) => a.start_time - b.start_time);
        this.setState({shiftsToday: response.data.length, shifts: sortedShifts });
      }
      if (response.data.length > 1) {
        let additionalShifts = this.state.shifts.splice(1);
        let shifts = [];
        shifts[0] = this.state.shifts[0];
        this.setState({ additionalShifts: additionalShifts, shifts: shifts });
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  getUpcomingShifts = () => {
    let self = this;
    let startTime = getUnixTime(startOfToday());
    let endTime = startTime + 2629800;
    axios.get("shifts/find_by_time_and_user/" + startTime + "/" + endTime).then((response) => {
      let sortedShifts = response.data;
      if (sortedShifts) {
        sortedShifts.sort((a, b) => a.start_time - b.start_time);
        self.setState({ upcomingShifts: sortedShifts, pageSize: sortedShifts.length })
      }
      self.setState({ noUpcoming: false })
      if (sortedShifts.length < 1) {
        self.setState({ noUpcoming: true })
      }
    }).catch((error) => {
      console.log(error)
    });
  }

  //update
  getOpenShifts = () => {
    let self = this;
    if (localStorage.getItem('group')) {
      axios.post("shifts/open_shifts/employee", { groups: localStorage.getItem('group').split(",") }).then((response) => {
        let sortedShifts = response.data;
        // console.log(sortedShifts)
        if (sortedShifts) {
          sortedShifts.sort((a, b) => {
            if (a.start_time < b.start_time || (a.start_time === b.start_time && a.end_time < b.end_time))
              return -1;
            if (a.start_time > b.start_time || (a.start_time === b.start_time && a.end_time > b.end_time))
              return 1;
            return 0;
          });
          self.setState({ openShifts: sortedShifts })
        }
      }).catch((error) => {
        console.log(error)
      });
    }
  }

  deleteOpen = (shift) => {
    if (window.confirm('Are you sure you want to delete this shift?')) {
      axios.delete("shifts/delete/" + shift._id).then((response) => {
        this.getOpenShifts();
        this.logAction("delete a shift");
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  handleBreak = (shift) => {
    this.setState({ breakModal: true, editShift: false, selectedShift: shift });
  }

  openModal = (shift) => {
    this.setState({ modal: true, editShift: false, selectedShift: shift });
  }

  hideModal = () => {
    this.setState({ modal: false, breakModal: false, editShift: false });
  }

  closeFlash = () => {
    this.setState({ message: false })
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  //show error/success message
  flash = () => {
    let ret = [];

    if (this.state.success) {
      ret.push(<Flash key="success" type="success" title="Success" messages={["Your edit has been saved!"]} closeFlash={() => this.setState({ success: false })} />);
    }

    if (this.state.error) {
      let errTitle = "Incomplete Form";
      let errMessage = this.state.error.toString();

      //check for systen error
      if (errMessage.slice(0, 5) === "SYS!:") {
        errTitle = "System Error";
        errMessage = errMessage.slice(5, errMessage.length);
      }

      ret.push(
        <Flash key="error" type="error" title={errTitle} messages={[errMessage]} closeFlash={() => this.setState({ error: false })} />
      );

    }

    return ret;
  };

  refreshShifts = () => {
    this.getShiftsToday();
    this.getUpcomingShifts();
    this.getOpenShifts();
  }

  updateAndNotify = (shift, group) => {
    teamsNotify(shift, group, `${shift.employee.display_name} has offered up a shift`);
    this.refreshShifts();
    this.logAction("offer up shift");
  }

  render() {
    let home = [];
    let warning = this.state.message ?
      <Flash type="warning" title="Update" messages={this.state.message} closeFlash={this.closeFlash} /> : null;
    home.push(
      <div key='vertical'>
        <div>
          <div>
            <div>
              <CurrentShift name={this.state.name} shiftsToday={this.state.shiftsToday} shifts={this.state.shifts}
                getShifts={this.getShiftsToday} setFlash={this.setFlash} handleBreak={this.handleBreak}/>
            </div>
          </div>
          <div style={{ marginLeft: '40px', marginRight: '40px' }}>{this.flash()}</div>
          <div className="home-main">
            <UpcomingShifts upcomingShifts={this.state.upcomingShifts} additionalShifts={this.state.additionalShifts}
              noUpcoming={this.state.noUpcoming} groups={this.state.groups} openModal={this.openModal}
              refreshShifts={this.refreshShifts} updateAndNotify={this.updateAndNotify}
              logAction={this.logAction} />
            <OpenshiftPool openShifts={this.state.openShifts} getShiftsToday={this.getShiftsToday}
              getOpenShifts={this.getOpenShifts} getUpcomingShifts={this.getUpcomingShifts} setFlash={this.setFlash}
              logAction={this.logAction} refreshShifts={this.refreshShifts} noUpcoming={this.state.noUpcoming}
              groups={this.state.groups} />
          </div>
        </div>
      </div>
    );
    return (
      <div>
        {warning}
        {/* <br /> */}
        <div>
          <ShiftModal modal={this.state.modal} onClose={this.hideModal} refreshShifts={this.refreshShifts} shift={this.state.selectedShift} />
          <TakeABreakModal modal={this.state.breakModal} onClose={this.hideModal} refreshShifts={this.refreshShifts} shift={this.state.selectedShift} />
          {home}
        </div>
      </div>
    );
  }
}


export default HomeIndex;
