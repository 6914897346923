import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

//import axios from 'axios';

class Week extends Component {
    constructor(props) {
        super();
        this.state = {
            eventInfo: {
                daysOfWeek: [],
                start: '',
                end: '',
                prettyStart: '',
                prettyEnd: '',
                days: [false, false, false, false, false, false, false],
                type: 'weekly_availability'
            },
            oldEvent: {
                daysOfWeek: [],
                start: '',
                end: '',
                prettyStart: '',
                prettyEnd: '',
                days: [false, false, false, false, false, false, false],
                type: 'weekly_availability'
            },
            weekday: ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'],
            edit: 'Add',
            weekData: '',
            type: 'weekly_availability'
        }
    }

    calendarRef = React.createRef()

    // if start or end date is updated, update fullcalendar
    componentDidUpdate(prevProps) {
        let changedProps = prevProps.data !== this.props.data
        if (changedProps) {
            this.calendarRef.current.getApi().refetchEvents() // refresh fullcalendar
        }
    }

    componentDidMount() {
        this.props.getData();
    }

    // when the time slot is clicked, show modal
    handleEventClick = (eventInfo) => {

        // console.log(eventInfo.event);
        this.props.setFlash(false, false);

        this.setState({ oldEvent: this.eventClickDateFormat(eventInfo.event) });
        this.setState({ eventInfo: this.eventClickDateFormat(eventInfo.event) });
        this.setState({ edit: 'Save' });
    }

    //when user input a start time, update event
    handleTimeChange = (event) => {
        let newInfo = this.state.eventInfo;
        const name = event.target.name;
        const value = event.target.value;
        let time = value.split(":")
        newInfo[name] = time[0] + ":" + time[1];
        if (name === 'prettyStart') {
            newInfo['start'] = parseInt(time[0]) * 100 + parseInt(time[1]);
        } else {
            newInfo['end'] = parseInt(time[0]) * 100 + parseInt(time[1]);
        }
        this.setState({ eventInfo: newInfo });
    }

    //when a time period is selected on the calendar, it takes in dateInfo
    //and sets an updated eventIno
    handleDateSelect = (dateInfo) => {
        this.props.setFlash(false, false);

        // console.log(dateInfo);

        //if the user clicked on daygrid, the time returns as 00:00, so change it to 08:00
        var event = this.eventClickDateFormat(dateInfo);
        // console.log(event);

        if (!event.sameDay) {
            var error = [];
            error.push('Please keep the shift on the same day!')
            this.props.setFlash(error, false);
        } else {
            // console.log(event);

            this.setState({ eventInfo: event });
            this.setState({ edit: 'Add' });
            // console.log(event);

        }

    }

    //format and get the hour and min info from the given event object
    eventClickDateFormat = (event) => {

        let startDay = event.start.toString().substring(0, 3);
        if (startDay.toLowerCase() === 'thu') {
            startDay = 'thur';
        }
        let endDay = event.end.toString().substring(0, 3);
        if (endDay.toLowerCase() === 'thu') {
            endDay = 'thur';
        }
        let dayInt = this.state.weekday.indexOf(startDay.toLowerCase());
        let newDays = [false, false, false, false, false, false, false];
        newDays[dayInt] = true;
        let type = (event.extendedProps && event.extendedProps.type) || "weekly_availability";

        this.setState({ type: type });

        return {
            daysOfWeek: [dayInt],
            start: event.start.getHours() * 100 + event.start.getMinutes(),
            end: event.end.getHours() * 100 + event.end.getMinutes(),
            prettyStart: format(event.start, "HH:mm"),
            prettyEnd: format(event.end, "HH:mm"),
            days: newDays,
            sameDay: startDay === endDay,
            type: type 
        };
    }

    //saves the time slot, save info in the data base
    handleSave = () => {
        var error = [];
        if (this.state.eventInfo.daysOfWeek.length === 0) {
            error.push('Please select a start day!')
            this.props.setFlash(error, false);
        } else if (this.state.eventInfo.end <= this.state.eventInfo.start) {
            error.push('Please make sure the end time is after the start time!')
            this.props.setFlash(error, false);
        } else if (this.state.eventInfo.start === '' || this.state.eventInfo.end === '') {
            error.push('Please have both start time and end time!')
            this.props.setFlash(error, false);
        } else {

            let oldEvent = {}

            //if the user is editing, delete the old event
            if (this.state.edit === 'Save') {
                oldEvent = {
                    start: this.state.oldEvent.start,
                    end: this.state.oldEvent.end,
                    day: this.state.weekday[this.state.oldEvent.daysOfWeek],
                    type: this.state.oldEvent.type
                };
            }

            for (let day of this.state.eventInfo.daysOfWeek) {
                // console.log(day);

                let addEvent = {
                    start: this.state.eventInfo.start,
                    end: this.state.eventInfo.end,
                    prettyStart: this.state.eventInfo.prettyStart,
                    prettyEnd: this.state.eventInfo.prettyEnd,
                    day: this.state.weekday[day],
                    type: this.state.type
                };

                if (this.state.edit === 'Add') {
                    this.addEvent(addEvent);
                } else if (addEvent.day === oldEvent.day && addEvent.type === oldEvent.type) {
                    addEvent.prevStart = oldEvent.start;
                    addEvent.prevEnd = oldEvent.end;
                    this.editEvent(addEvent);
                } else {
                    this.deleteEvent(oldEvent);
                    this.addEvent(addEvent);
                }
            }

            this.handleCancel();
        }

    }

    //cancels modification of the time slot
    handleCancel = () => {

        //generatae an empty event
        let event =
        {
            daysOfWeek: [],
            start: '',
            end: '',
            prettyStart: '',
            prettyEnd: '',
            days: [false, false, false, false, false, false, false],
            type: "weekly_availability"
        };
        this.setState({ eventInfo: event });
        this.setState({ oldEvent: event });
        this.setState({ edit: 'Add' });

    }

    addEvent = (addEvent) => {
        axios.put(
            `users/add_weekly_availability`,
            addEvent
        ).then((response) => {
            this.props.getData();
            this.handleCancel();
            this.props.setFlash(false, ['Your availability is saved!']);
        }).catch(function (err) {
            console.log(err)
        });
    }

    //send to the db that the given day event is to be deleted
    deleteEvent = (day) => {
        console.log(day);
        axios.put("users/delete_weekly_availability", day).then((response) => {
            this.props.getData();
            this.handleCancel();
            this.props.setFlash(false, ['Your availability is saved!']);
        }).catch((error) => {
            console.log(error);
        });
    }

    //takes in an old event to remove and a new event to add
    //checks if the new event overlaps with anything
    editEvent = (addEvent) => {
        axios.put(
            "users/edit_weekly_availability/",
            addEvent
        ).then((response) => {
            this.props.getData();
            this.handleCancel();
            this.props.setFlash(false, ['Your availability is saved!']);
        }).catch(function (err) {
            console.log(err)
        });
    }

    //deletes the time slot
    //gets the event's day info and finds the event in the db on the day
    //calls the delete event method to delete the event
    handleDelete = () => {
        if (this.state.weekData) {
            let type = this.state.eventInfo.type;
            let days = this.props.data[type];
            // console.log(days);
            for (let dayOfWeek of this.state.eventInfo.daysOfWeek) {
                for (let day of days[this.state.weekday[dayOfWeek]]) {
                    if (this.state.eventInfo.start === day.start && this.state.eventInfo.end === day.end) {
                        day.day = this.state.weekday[dayOfWeek];
                        day.type = type;
                        // console.log(day);
                        this.deleteEvent(day);
                    }
                }
            }
        }
        this.handleCancel();
    }

    //if it's in adding mode, the user click on checkbox, it is changed
    //if it's in saving mode, the radio button shows changes
    handleCheckboxChange = (event) => {
        var newEventInfo = this.state.eventInfo;
        // console.log(event.target.name);
        var day = parseInt(event.target.name);
        if (this.state.edit === 'Add') {
            // console.log(event.target.name);
            var daysOfWeek = newEventInfo.daysOfWeek;
            if (!daysOfWeek.includes(day)) {          //checking weather array contain the id
                daysOfWeek.push(day);               //adding to array because value doesnt exists
                newEventInfo.days[day] = true;
            } else {
                daysOfWeek.splice(daysOfWeek.indexOf(day), 1);  //deleting
                newEventInfo.days[day] = false;
            }
        } else {
            daysOfWeek = [];
            daysOfWeek.push(day);
            var days = [false, false, false, false, false, false, false];
            days[day] = true;
            newEventInfo.days = days;
        }
        newEventInfo.daysOfWeek = daysOfWeek;
        this.setState({ eventInfo: newEventInfo });
        // console.log(this.state.eventInfo.daysOfWeek);
    }

    //this is called when a user changes schedule on the calendar
    //accepts an event object that includes the old event time and the new event time
    handleDrop = (event) => {
        let addEvent = this.grabDateFormat(event.event);
        let oldEvent = this.grabDateFormat(event.oldEvent);
        let diffDay = addEvent.day !== oldEvent.day;
        // console.log(diffDay);
        // console.log(noOverlaping);
        if (!diffDay && addEvent.type === oldEvent.type) {
            addEvent.prevStart = oldEvent.start;
            addEvent.prevEnd = oldEvent.end;
            this.editEvent(addEvent);
        } else {
            console.log(oldEvent, addEvent);
            this.addEvent(addEvent);
            this.deleteEvent(oldEvent);
        }

    }

    //grab the event hour, min, day, start and end from the given event and returns a formated object
    grabDateFormat = (event) => {
        var dayStart = event.start.toString().substring(0, 3).toLowerCase();
        if (dayStart === 'thu') {
            dayStart = 'thur';
        }

        //the old date
        var grabEvent = {
            start: event.start.getHours() * 100 + event.start.getMinutes(),
            end: event.end.getHours() * 100 + event.end.getMinutes(),
            prettyStart: format(event.start, "HH:mm"),
            prettyEnd: format(event.end, "HH:mm"),
            day: dayStart,
            type: event.extendedProps.type || "weekly_availability"
        }
        // console.log(grabEvent);

        return (grabEvent);
    }

    handleChange = (e) => {
        this.setState({ type: e.target.value });
    }

    //create a time slot on the form
    createAvailability = () => {
        return (
            <div className="card">
                <div className="card-content">
                    <form>
                    {/* <h2 className="title is-4">Time Sheet</h2> */}
                        <h2 className="title">Time Sheet</h2>
                        {this.checkbox()}

                        <div className="field">
                            <div className="control">
                            {/* <h3 className="label">Time</h3> */}
                                <h3 className="label">Time</h3>
                                <label> From: <input className='input' type="time" name="prettyStart" value={this.state.eventInfo.prettyStart} onChange={this.handleTimeChange} /></label>
                                <label> To: <input className='input' type="time" name="prettyEnd" value={this.state.eventInfo.prettyEnd} onChange={this.handleTimeChange} /></label>
                            </div>
                        </div>

                        <div className="control profile">
                            <label className="radio">
                                Preferred Hours
                                <input type="radio" name="hours" onChange={this.handleChange} value="weekly_availability" checked={this.state.type === "weekly_availability"} />
                            </label>
                            <label className="radio">
                                Available Hours
                                <input type="radio" name="hours" onChange={this.handleChange} value="weekly_maybe" checked={this.state.type === "weekly_maybe"} />
                            </label>
                            <label className="radio">
                                Unavailable Hours
                                <input type="radio" name="hours" onChange={this.handleChange} value="weekly_unavailability" checked={this.state.type === "weekly_unavailability"} />
                            </label>
                        </div>

                    </form>
                    {this.buttonGroup()}
                </div>
            </div >
        )
    }

    //shows buttons like add, save, cancel and delete
    buttonGroup = () => {
        var deleteButton = <button className="button" type="button" onClick={this.handleDelete}>Delete</button>;
        if (this.state.edit === 'Add') {
            deleteButton = null;
        }
        return (
            <div className="buttons is-grouped">
                <button className="button is-link" type="button" onClick={this.handleSave}>{this.state.edit}</button>
                <button className="button is-light" type="button" onClick={this.handleCancel}>Cancel</button>
                {deleteButton}
            </div>
        )
    }

    //if it's saving, radio button
    //if it's adding, checkbox
    checkbox = () => {
        var type = this.state.edit === 'Add' ? "checkbox" : 'radio';
        let days = this.state.eventInfo.days;
        let checkboxes = [];
        let index = 0;
        this.state.weekday.map((day) => {
            checkboxes.push(<label className="checkbox" key={day}><input name={index + ''} type={type} checked={days[index]} onChange={this.handleCheckboxChange} /> {day}</label>)
            index++;
            return index;
        })
        return (

            <div className="field">
                <div className="control">
                    <h3 className="label">Day</h3>
                    {checkboxes}
                </div>
            </div>
        )
    }

    // overrides fullcalendar's event display
    renderEventContent(eventInfo) {
        if (eventInfo.event.start && eventInfo.event.end && eventInfo.event.start >= 0) {
            // console.log(eventInfo.event.extendedProps)
            if (eventInfo.event.extendedProps.type === "weekly_maybe"){
                eventInfo.backgroundColor = "#DAA520";
            } else if (eventInfo.event.extendedProps.type === "weekly_unavailability"){
                eventInfo.backgroundColor = "#A52A2A";
            } else {
                eventInfo.backgroundColor = "#228B22";
            }
            return (
                <div className="event">

                    {/* bolded time range: start-end  */}
                    <p className="bold lower">
                        {format(eventInfo.event.start, "hh:mma")}&#8211;{format(eventInfo.event.end, "hh:mma")}
                    </p>
                </div>
            )
        }
        // }
    }

    //create a calendar that contains the fullcalendar
    createCalendarPage = () => {
        return (
            <div className="card">
                <div className="card-content">
                    <div className="tabcontent">
                        <FullCalendar
                            ref={this.calendarRef}
                            plugins={[timeGridPlugin, interactionPlugin]}
                            headerToolbar={false}
                            dayHeaderFormat={{ weekday: 'long' }} // show days of the week at the top of the calendar
                            allDaySlot={false} // no all day events
                            nowIndicator={true} // red line and arrow to indicate current time
                            showNonCurrentDates={false} // month calendar grays out days not in current month
                            scrollTime={'07:00:00'} // week/day view begins at 7 am
                            height={600}
                            editable={true}
                            selectable={true}
                            select={this.handleDateSelect}
                            eventClick={this.handleEventClick}
                            initialEvents={this.getEventInfo}
                            eventChange={this.handleDrop}
                            slotEventOverlap={false}
                            eventContent={this.renderEventContent}
                        />
                    </div>
                </div>
            </div >
        )
    }

    //takes in a day object that contains a start and end time and a key of the day of the week
    //returns a time object with start time and end time
    createEvent = (day, key, type) => {
        let time = {
            startTime: day.prettyStart,
            endTime: day.prettyEnd,
            daysOfWeek: [this.state.weekday.indexOf(key)],
            type: type
        }
        return time;
    }

    getEachEvent = (days, events, type) => {
        if (days) {
            for (let day of this.state.weekday) {
                if (days[day]) {
                    // console.log(days[day]);
                    for (let availability of days[day]) {
                        // console.log(day);
                        let time = this.createEvent(availability, day, type);
                        // console.log(time);
                        events.push(time);
                    }
                }
            }
        }
    }

    //get initial events
    getEventInfo = (info, successCallback, failureCallback) => {
        // console.log(this.props.data.weekly_shifts);
        let events = [];
        if (this.props.data) {
            this.getEachEvent(this.props.data.weekly_availability, events, "weekly_availability");
            this.getEachEvent(this.props.data.weekly_maybe, events, "weekly_maybe");
            this.getEachEvent(this.props.data.weekly_unavailability, events, "weekly_unavailability");
            // console.log(events)
            this.setState({ weekData: events });
        }
        successCallback(events);
    }

    //give user instruction on how to use this
    instructions = () => {
        let type = this.props.isUnavailability === "Weekly Unavailability" ? "Please fill out when you're NOT available to work." :
            "Please fill out when you ARE available to work."
        return <>
            <span className="hover-undefined">
                <FontAwesomeIcon className="fa-question-circle fa-fw" icon={faQuestionCircle} />
            </span>
            <div className="hide">
                <p><span className="bold">IMPORTANT:</span>{type}</p>
                <p><span className="bold">Add time:</span> Enter the information on the form or drag time slots on the calendar.</p>
                <p><span className="bold">Colors:</span> Red means unavailable time. Yellow means available but not preferred time. Greens means preferred time. </p>
                <p><span className="bold">Update time:</span> Click on a shift and edit with the form or drag shift to different time slot on the calendar.</p>
                <p><span className="bold">Delete time:</span> Click a shift and delete with the form's delete button.</p>
            </div>
        </>
    }

    render() {
        return (
            <div>
                <div className='profile-header'><h1 className="title profile">{this.props.isUnavailability} Calendar </h1> {this.instructions()}</div>
                <div className="columns">
                    <div className="column is-3">
                        {this.createAvailability()}
                    </div>
                    <div className="column">
                        {this.createCalendarPage()}
                    </div>
                </div>
            </div>
        )
    }
}

export default Week;