import React, { Component } from 'react';
import axios from 'axios';
import Flash from '../common/flash';
import ManageGroups from './manageGroups'
import ActAs from './actAs';
import ChangeRole from './changeRole';
import format from "date-fns/format";
import Table from '../common/table';
import { getWeek } from "date-fns";

class AdminIndex extends Component {
  constructor(props) {
    super();
    this.state = { admin: true, payperiods: [], staffHolidays: [] }
  }

  componentDidMount() {
    this.isTrueAdmin();
  }

  isTrueAdmin = () => {
    let self = this;
    axios.get("saml/check_true_admin").then((response) => {
      if (response.data.role !== "admin") { self.setState({ admin: false }) }
    }).catch(function (err) {
      console.log(err)
    });
  }

  //show error/success/warning message
  flash = () => {
    let ret = [];

    if (this.state.warning) {
      ret.push(<Flash key="warning" type="warning" title="Warning!" messages={[this.state.warning]} closeFlash={() => this.setState({ warning: false })} />);
    }

    if (this.state.success) {
      ret.push(<Flash key="success" type="success" title="Success" messages={["Your edit has been saved!"]} closeFlash={() => this.setState({ success: false })} />);
    }

    if (this.state.error) {
      let errTitle = "Incomplete Form";
      let errMessage = this.state.error.toString();

      //check for systen error
      if (errMessage.slice(0, 5) === "SYS!:") {
        errTitle = "System Error";
        errMessage = errMessage.slice(5, errMessage.length);
      }

      ret.push(
        <Flash key="error" type="error" title={errTitle} messages={[errMessage]} closeFlash={() => this.setState({ error: false })} />
      );

    }

    return ret;
  };

  updateFlash = (message) => {
    this.setState(message);
  }

  handleMessage = (path) => {
    let group = localStorage.getItem('group').split(',')[0];
    axios.get(`messageScheduler/${path}/${group}`).then(response => {
      console.log("response", response);
    }).catch((error) => {
      console.log(error)
    });
  }

  handleMessageAll = (path) => {
    axios.get(`messageScheduler/${path}`).then(response => {
      console.log("response", response);
    }).catch((error) => {
      console.log(error)
    });
  }

  getBiWeeklySchedule = () => {
    axios.get('messageScheduler/getBiWeeklySchedule').then(response => {
      const data = response.data;
      this.setState({ payperiods: data })
    }).catch((error) => {
      console.log(error)
    });
  }

  bullMQQueue = () => {
    return <div className="card">
      <div className="card-content">
        <h3 className="label">CoLab Queue Management</h3>
        <div className="field is-grouped">
          <input className="button is-info is-light " onClick={() => this.handleMessage('test')} value="Test Queue" type="button" />
          <input className="button is-info is-light " onClick={() => this.handleMessage('getGroupQueue')} value="Set Queue for CoLab" type="button" />
          <input className="button is-info is-light " onClick={() => this.handleMessage('clearQueue')} value="Clear Queue for CoLab" type="button" />
          <input className="button is-info is-light " onClick={() => this.handleMessage('clearAndSetQueue')} value="Clear and Set Queue for CoLab" type="button" />
        </div>
        <h3 className="label">All Groups Queue Management</h3>
        <div className="field is-grouped">
          <input className="button is-info is-light " onClick={() => this.handleMessageAll('getGroupsQueue')} value="Set Queue for All Groups" type="button" />
          <input className="button is-info is-light " onClick={() => this.handleMessageAll('clearAndSetAllGroupsQueue')} value="Clear and Set Queue All Groups" type="button" />
        </div>
      </div>
    </div>
  }

  showPayPeriod = () => {
    const cols = [{ title: "Future Pay Periods Ends", data: "enddate" }, {title: "ISO Week(starts at 0)", data: "isoweek"}];
    let newData = []
    this.state.payperiods.forEach(p => {
      let rawData = new Date(p["Pay Period End Date"]);
      if (new Date() < rawData) {
        newData.push({
          "enddate": format(rawData, 'yyyy-MM-dd'),
          "isoweek": getWeek(rawData, {
            weekStartsOn: 1 // week starts on monday
          }) 
        })
      }
    })
    // console.log(newData);
    return <div className="card">
      <div className="card-content">
        <h3 className="label">Pay Periods</h3>
        <p><i>Shiftr ISOWeeks starts on Monday</i></p>
        <div className="field is-grouped">
          <input className="button is-info is-light " onClick={() => this.getBiWeeklySchedule()} value="Display Pay Periods" type="button" />
        </div>
        <Table data={newData} columns={cols} />
      </div>
    </div>
  }

  getYearlyHolidayQueue = () => {
    axios.get('messageScheduler/getYearlyHolidayQueue').then(response => {
      const data = response.data;
      console.log(data);
    }).catch((error) => {
      console.log(error)
    });
  }

  setHolidaysQueues = () => {
    axios.get('messageScheduler/setHolidaysQueues').then(response => {
      const data = response.data;
      console.log(data);
    }).catch((error) => {
      console.log(error)
    });
  }

  displayStaffHolidays = () => {
    axios.get('messageScheduler/getStaffHolidays').then(response => {
      const data = response.data;
      // console.log(data);
      this.setState({ staffHolidays: data })
    }).catch((error) => {
      console.log(error)
    });
  }

  showStaffHolidays = () => {
    const cols = [{ title: "Holiday", data: "holiday" }, {title: "Day of the Week", data: "DOW"}, {title: "Date", data: "date"}];
    let newData = []
    this.state.staffHolidays.forEach(h => {
      newData.push({
        "holiday": h["Holiday"],
        "DOW": h["Day of the Week"],
        "date": format(new Date(h["Date"]), 'MM-dd-yyyy')
      })
    })
    // console.log(newData);
    return <div className="card">
      <div className="card-content">
        <h3 className="label">Staff Holidays</h3>
        <div className="field is-grouped">
          <input className="button is-info is-light " onClick={() => this.displayStaffHolidays()} value="Display Staff Hoidays" type="button" />
          <input className="button is-info is-light " onClick={() => this.getYearlyHolidayQueue()} value="Set Queue to Get Yearly Staff Hoidays" type="button" />
          <input className="button is-info is-light " onClick={this.setHolidaysQueues} value="Set Queues for Holidays" type="button" />
        </div>
        <Table data={newData} columns={cols} />
      </div>
    </div>
  }

  adminConsole = () => {
    if (this.state.admin) {
      return <div>
        <br />
        <ActAs />
        <br />
        <ChangeRole updateFlash={this.updateFlash} />
        <br />
        {this.bullMQQueue()}
        <br />
        {this.showPayPeriod()}
        <br />
        {this.showStaffHolidays()}
        <br />
        <ManageGroups updateFlash={this.updateFlash} />
      </div>
    } else {
      // TODO this should use the flash component
      return <div className="notification is-danger">
        <button className="delete"></button>
        You must be an admin to access this page.
      </div>
    }
  }

  render() {
    return (
      <div className="container">
        {this.flash()}
        {this.adminConsole()}

      </div>
    );
  }
}


export default AdminIndex;
