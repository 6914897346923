import React, { Component } from 'react';

class Flash extends Component {
  constructor(props) {
    super();
    this.state = {}
  }

  handClick = () => {
    this.props.closeFlash();
  }

  flashMessages = () => {
    let messages = []
    this.props.messages.forEach((msg, index) => messages.push(<li key={index}>{msg}</li>))
    return messages
  }

  flashClass = () => {
    if (this.props.type === "error") {
      return "message is-danger";
    } else if (this.props.type === "success") {
      return "message is-primary";
    } else if (this.props.type === "warning") {
      return "message is-warning"
    }
  }

  hide = () => {
    if (this.props.type === 'hide'){
      return 'is-hide';
    } else {
      return 'sticky'
    }
  }

  render() {
    return (
      <div className={this.hide()}>
        <article className={this.flashClass()}>
          <div className="message-header">
            <h3>{this.props.title}</h3>
            <button className="delete" aria-label="delete" onClick={this.handClick}></button>
          </div>
          <div className="content message-body">
            <ul>{this.flashMessages()}</ul>
          </div>
        </article>
      </div>
    );
  }
}


export default Flash;
