import React, { Component } from 'react'
import format from 'date-fns/format'

class ShowShift extends Component {

    //create a time slot on the form 
    displayShift = (shift) => {
        // console.log(shift)
        return <div key={shift.shiftId}>
            <p><strong>Shift Details:</strong></p>
            {/* time range of shift */}
            <p>
                {format(shift.start_time, "MM/dd h:mm a")} &#8211; {format(shift.end_time, "MM/dd h:mm a")}
            </p>

            {/* more details about the shift */}
            <section>
                <p>
                    <span className="bold">Group: </span>
                    {shift.group}
                </p>
                <p>
                    <span className="bold">Location: </span>
                    {shift.location}
                </p>
                <p>
                    <span className="bold">Employee: </span>
                    {shift.employee ?
                        shift.employee.display_name || shift.employee?.name :
                        'Open'}
                </p>
                <p hidden={shift.note ? false : true}>
                    <span className="bold">Note: </span>
                    {shift.note}
                </p>
            </section>
            <br />
        </div>
    }

    render() {
        return (
            <div className="card show-overflow">
                <div className="card-content">
                    <h2 className="title">Shifts to Delete:</h2>
                    {this.props.selectedShifts.map(shift => this.displayShift(shift))}
                </div>
            </div>
        );
    }
}

export default ShowShift;