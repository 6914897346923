import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import axios from 'axios';

const setAttributes = (data) => {
  let name;
  if (data.display_name) {
    name = data.display_name.split(" ");
  } else {
    name = data.name.split(" ");
  }
  localStorage.setItem("firstName", name[0]);
  localStorage.setItem("lastName", name[name.length - 1]);
  localStorage.setItem("email", data.eppn);
  localStorage.setItem("netid", data.netid);
  localStorage.setItem("role", data.role);
  localStorage.setItem("group", data.group);
  localStorage.setItem("loggedIn", true);
  localStorage.setItem("actAs", data.actAs);
  localStorage.setItem("displayName", data.display_name);
}

const checkLogin = () => {
    return axios.get("saml/checkLogin").then((response) => {
        if (!localStorage.getItem("loggedIn")) {
            setAttributes(response.data)
        }
        console.log("you're in")
        return true
    }).catch((error) => {
        console.log(error)
    });
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ children, ...rest }) => {
    return (
        <Route
            {...rest}
            render={() =>
                checkLogin() ? (
                    children
                ) : (
                    <Redirect push to='/' />
                )
            }
        />
    );
}

export default PrivateRoute;