import React, { Component } from 'react';
import format from 'date-fns/format'
import ShiftModal from "./shiftModal";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

// a table that display shifts details
export default class ShiftsDetailTable extends Component {
   constructor(props) {
      super();
      this.state = { canEdit: this.checkCanEdit(), shifts: null, scheduleNames: {}, grace: 10, clocked: 15 }
   }

   componentDidMount() {
      if (localStorage.getItem("role") !== "employee") { this.getScheduleName() }
      this.setState({ shifts: this.props.data.shifts });
   }

   componentDidUpdate(prevProps) {
      let changedProps = prevProps.data.shifts !== this.props.data.shifts
      if (changedProps) {
         this.setState({ shifts: this.props.data.shifts })
      }
   }

   getScheduleName = () => {
      let groups = localStorage.getItem('group').split(',');
      for (let i = 0; i < groups.length; i++) {
         axios.get(`schedule/drafts/${groups[i]}`)
            .then((response) => {
               let newScheduleNames = this.state.scheduleNames;
               response.data.map(d => newScheduleNames[d?.scheduleId] = d?.scheduleName);
               this.setState({ scheduleNames: newScheduleNames })
            }).catch((error) => {
               console.log(error)
            });
      }
   }

   checkCanEdit = () => {
      return true ? localStorage.getItem('role') === "supervisor" || localStorage.getItem('role') === "admin" : false
   }

   drawShifts = () => {
      let sizing = "";

      if (this.state.shifts) {
         let showScheduleName;
         if (localStorage.getItem("role") !== "employee") {
            showScheduleName = <th><h1 className='title is-6 table-head'>Schedule Name</h1></th>
         }
         return <div className={sizing}>
            <table className="table is-bordered is-striped is-narrow is-fullwidth">
               <thead>
                  <tr>
                     {showScheduleName}
                     <th><h1 className='title is-6 table-head'>Group</h1></th>
                     <th><h1 className='title is-6 table-head'>Date</h1></th>
                     <th><h1 className='title is-6 table-head'>Shift Time</h1></th>
                     <th><h1 className='title is-6 table-head'>Shift Hours</h1></th>
                     <th><h1 className='title is-6 table-head'>Clocked In</h1></th>
                     <th><h1 className='title is-6 table-head'>Clocked Out</h1></th>
                     <th><h1 className='title is-6 table-head'>Clocked Hours</h1></th>
                     <th><h1 className='title is-6 table-head'>Action</h1></th>
                  </tr>
               </thead>
               <tbody>
                  {this.mapShifts()}
               </tbody>
            </table>
         </div>
      }
   }


   mapShifts = () => {
      return this.state.shifts.map((shift, index) => {
         let complete = this.props.type === 'complete' && shift.total_shift_hours <= shift.total_clocked_hours;
         let incomplete = this.props.type === 'incomplete' && shift.total_shift_hours > shift.total_clocked_hours;
         let outofrange = this.props.type === 'outofrange' && (!shift.clock_in_ip_in_range || !shift.clock_out_ip_in_range);
         let addShift = complete || incomplete || outofrange || this.props.type === "all";
         let scheduleName = this.state.scheduleNames[shift.scheduleId];
         let clockedInLate = shift.clocked_in > shift.start_time + this.state.grace * 60;
         let clockedOutEarly = shift.clocked_out < shift.end_time - this.state.grace * 60;
         let differenceBetweenHours = Math.abs((shift.clocked_out - shift.clocked_in) - (shift.end_time - shift.start_time));
         let showScheduleName;
         if (localStorage.getItem("role") !== "employee") {
            showScheduleName = <td> {scheduleName || '-'}</td>
         }
         let clockedHours = Math.round(shift.total_clocked_hours / 360) / 10;
         if (addShift) {
            return <tr key={index}>
               {showScheduleName}
               <td> {shift.group}</td>
               <td> {format(shift.start_time * 1000, "M/d/y")}</td>
               <td> {format(shift.start_time * 1000, "h:mm a")} &#8211; {format(shift.end_time * 1000, "h:mm a")}</td>
               <td> {Math.round((shift.end_time - shift.start_time) / 360) / 10}</td>
               <td>
                  <span className={`table-icon tooltip ${shift.clock_in_ip_in_range ? 'green' : 'red'}`}>
                     {shift.clocked_in ? format(shift.clocked_in * 1000, "h:mm a") : "N/A"}{'    '}
                     <span className="tooltiptext">green: ip in range; red: ip not in range</span>
                  </span>
                  {clockedInLate ? <span className="tag is-warning">Late</span> : ''}
               </td>
               <td>
                  <span className={`table-icon tooltip ${shift.clock_out_ip_in_range ? 'green' : 'red'}`}>
                     {shift.clocked_out ? format(shift.clocked_out * 1000, "h:mm a") : "N/A"}{'    '}
                     {clockedOutEarly ? <span className="tag is-warning">Left Early</span> : ''}
                     <span className="tooltiptext">green: ip in range; red: ip not in range</span>
                  </span>
               </td>
               <td>
                  <span className={`table-icon tooltip ${shift.clock_in_ip_in_range ? 'green' : 'red'}`}>
                     {clockedHours} {'   '}
                     {differenceBetweenHours > this.state.clocked * 60 || !clockedHours ? <FontAwesomeIcon className="red" icon={faExclamation} /> : ''}
                     <span className="tooltiptext">exclamation: over or under hours</span>
                  </span>
               </td>
               <ShiftModal getShifts={this.props.getShifts} drawShifts={this.drawShifts} shift={shift} />
            </tr> 
         } else {
            return ''
         }
      })
   }

   handleChange = (e, name) => {
      this.setState({ [name]: e.target.value });
   }

   gracePeriodExplained() {
      return <>
         <span className="hover-undefined">
            <FontAwesomeIcon className="fa-question-circle fa-fw" icon={faQuestionCircle} />
         </span>
         <div className="hide">
            <p>This filter sets how many mins count as "late" or "left early".</p>
            <p>For example: Anni clocked in at 10:05 am for a 10:00 am shift. If the filter is set to 5 mins, Anni would not be counted as "late".</p>
            <br />
         </div>
      </>

   }

   clockedHoursExplained() {
      return <>
         <span className="hover-undefined">
            <FontAwesomeIcon className="fa-question-circle fa-fw" icon={faQuestionCircle} />
         </span>
         <div className="hide">
            <p>This filter alerts you if the clocked hours are more or less than ideal.</p>
            <p>For example: Anni completes for a 2.5 hours shift. If the filter is set to 15 mins, if would alert the supervisor the clocked hours are not acceptable.</p>
            <br />
         </div>
      </>

   }

   gracePeriod = () => {
      return <div className="field is-grouped grace-period">
         <div className="control">
            <label className="label">Grace Period:
               {this.gracePeriodExplained()}
            </label>
            <div className="control">
               <span> 0</span>
               <input type="range" step={5} min={0} max={30} value={this.state.grace} onChange={(e) => this.handleChange(e, "grace")} />
               <span> 30 mins</span>
               <p className='show-grace' style={{ left: 20 + Number(((this.state.grace - 0) * 100) / (30 - 0)) }}>{this.state.grace}</p>
            </div>
         </div>
         <div className="control">
            <label className="label">Acceptable Hours Difference:
               {this.clockedHoursExplained()}
            </label>
            <div className="control">
               <span> 0</span>
               <input type="range" step={15} min={0} max={120} value={this.state.clocked} onChange={(e) => this.handleChange(e, "clocked")} />
               <span> 120 mins</span>
               <p className='show-grace' style={{ left: 20 + Number(((this.state.clocked - 0) * 100) / (120 - 0)) }}>{this.state.clocked}</p>
            </div>
         </div>
      </div>
   }

   render() {
      return (
         <div>
            {this.props.employeeView ? '' : this.gracePeriod()}
            {this.drawShifts()}
         </div>
      )
   }
}