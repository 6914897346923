export const writeGlobalFlash = (flashHeading, flashMsg, type="is-danger") => {
  const flashDiv = document.getElementById('global-flash');
  if (flashDiv) {
    clearGlobalFlash();
    flashDiv.innerHTML += `
        <article class="message ${type}">
          <div class="message-header">
            <p>${flashHeading}</p>
            <button class="delete" id="global-flash-close" aria-label="close"></button>
          </div>
          <div class="message-body">
          ${flashMsg}
          </div>
        </article>
  `;
    document
      .getElementById('global-flash-close')
      .addEventListener('click', clearGlobalFlash);
    flashDiv.scrollIntoView();
  }
};

export const clearGlobalFlash = () => {
  const flashDiv = document.getElementById('global-flash');
  if (flashDiv) {
    flashDiv.innerHTML = '';
  }
};
