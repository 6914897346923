import React from 'react';
import axios from 'axios';
import distinctColors from 'distinct-colors';
import DraftCalendar from './draftCalendar';
import Flash from '../common/flash'
import DraftForm from './draftForm';
import getHour from 'date-fns/getHours'
import getMinute from 'date-fns/getMinutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import format from 'date-fns/format'
import '../../css/calendar.css'
import ShowShift from './showShift';

class DraftIndex extends React.Component {

    constructor(props) {
      super();
      this.state = {
        employee: null,
        start_time: '',
        end_time: '',
        num_shifts: 1,
        repeating: false,
        start_recur: null,
        end_recur: null,
        days_of_week: null,
        location: '',
        locations: null,
        edit: 'Add',
        currentIso: null,
        showFlash: null,
        calendarEndDate: props.data.end_time * 1000,
        calendarStartDate: props.data.start_time * 1000,
        note: '',
        highlightedEmployee: "--Select--",
        bulkSelect: false,
        selectedShiftIds: [],
        selectedShifts: []
      }
      this.myRef = React.createRef();
    }
  
    calendarRef = React.createRef()


    componentDidUpdate(prevProps) {
      let changedProps = prevProps.data !== this.props.data
      if (changedProps) {
        this.setState({
          calendarEndDate: this.props.data.end_time * 1000,
          calendarStartDate: this.props.data.start_time * 1000
        })
        this.calendarRef.current.getApi().refetchEvents() // refresh fullcalendar
      }
    }


    componentDidMount() {
        this.getGroupLocations();
    }

    //set color code based off location
  
    setLocationColors = (self, locations) => {
      let colorCodes = {}
      let pallette = distinctColors({ count: locations.length })
      for (let i = 0; i < locations.length; i++) {
        colorCodes[locations[i]] = pallette[i]
      }
      self.setState({ colorCodes: colorCodes })
    }

    getGroupLocations = () => {
        let self = this;
        axios.get("workGroups/find/" + self.props.group).then((response) => {
            // console.log(response.data)
            self.setLocationColors(self, response.data.locations)
            self.setState({ locations: response.data.locations, location: response.data.locations[0] });
        }).catch(function (err) {
            console.log(err)
        });
    }


  //check if the user input time and location are wrong
  checkError = (start_time, end_time) => {
    let error = [];
    if (!start_time || !end_time) {
      error.push('Please enter time!');
      this.setFlash(error, false);
      return true;
    } else if (start_time >= end_time) {
      error.push('Please enter a start time before an end time!');
      this.setFlash(error, false);
      return true;
    } else {
      this.setFlash(false, false);
    }
    return false;
  }

  //check if an employee is available or has open shift time
  checkAvailability = (e, shift) => {
    // console.log(shift);
    let event = e.event;
    let free = false;
    if (!shift.employee) {
      this.updateDraftShift(shift);
      this.resetState();
    } else {
      if (shift.start_time && shift.end_time) {
        let start_time = getHour(e.event.start) * 100 + getMinute(e.event.start);
        let end_time = getHour(e.event.end) * 100 + getMinute(e.event.end);
        let day = e.event.start.toString().substring(0, 3).toLowerCase();
        let weekly = shift.employee.weekly_availability;
        if (weekly && weekly[day]) {
          let availability = weekly[day];
          availability.forEach(time => {
            // console.log(time, start_time, end_time)
            if (time.start <= start_time && time.end >= end_time) {
              free = true;
            }
          });
        }
        let maybe = shift.employee.weekly_maybe;
        if (maybe && maybe[day]) {
          let maybe_availability = maybe[day];
          maybe_availability.forEach(time => {
            if (time.start <= start_time && time.end >= end_time) {
              free = true;
            }
          });
        }
        if (free) {
          axios.get('shifts/find_time_fc/' + (event.start / 1000) + '/' + (event.end / 1000) + '/' + shift.employee.netid)
            .then((response) => {
              //check if there are any shifts that are not the current shift
              if (response.data.length < 1 || (response.data.length === 1 && response.data[0].shiftId === shift.shiftId)) {
                // console.log("Available!");
                this.updateDraftShift(shift);
                this.resetState();
              } else {
                if (window.confirm("This employee is not availability/scheduled. Do you still want to add schedule?")) {
                  this.updateDraftShift(shift);
                  this.resetState();
                } else {
                  e.revert();
                }
              }
            }).catch(function (err) {
              console.log(err)
            });
        } else {
          if (window.confirm("This employee is not availability/scheduled. Do you still want to add schedule?")) {
            this.updateDraftShift(shift);
            this.resetState();
          } else {
            e.revert();
          }
        }
      }
    }
  }

  //add shifts to the db
  addShiftsToDraft = (newShifts) => {
    let self = this;
    let formData = new FormData();
    formData.append("newShifts", JSON.stringify(newShifts));

    axios.post("schedule/add_shifts/" + this.props.data.scheduleId,
      formData,
      { headers: { 'content-type': 'application/form-data' } }
    ).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.resetState();
      this.setFlash(null, ['Your shift has been added!']);
    }).catch(function (err) {
      console.log(err)
    });
  }

  //update a shift in the db
  updateDraftShift = (shiftInfo) => {
    let self = this;
    let formData = new FormData();
    formData.append("updatedShift", JSON.stringify(shiftInfo));

    axios.post("schedule/update_shift/" + this.props.data.scheduleId,
      formData,
      { headers: { 'content-type': 'multipart/form-data' } }
    ).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.resetState();
      this.setFlash(null, ['Your shift has been updated!']);
    }).catch(function (err) {
      console.log(err)
    });
  }

  //delete a shift in the db
  deleteDraftShift = (shiftInfo) => {
    let self = this;
    let formData = new FormData();
    formData.append("deleteShift", JSON.stringify(shiftInfo));

    axios.post("schedule/delete_shift",
      formData,
      { headers: { 'content-type': 'multipart/form-data' } }
    ).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.resetState();
      this.setFlash(null, ['Your shift has been deleted!']);
    }).catch(function (err) {
      console.log(err)
    });
  }

  publishSingle = (shiftId) => {
    const self = this;
    axios.get("/shifts/publish/" + shiftId)
      .then((response) => {
        self.props.getSchedule(this.props.data.scheduleId);
        self.resetState();
        this.setFlash(null, ['Your shift has been published!']);
      }).catch((error) => {
        console.log(error)
      })
  }

  unpublishSingle = (shiftId) => {
    const self = this;
    axios.get("/shifts/unpublish/" + shiftId)
      .then((response) => {
        self.props.getSchedule(this.props.data.scheduleId);
        self.resetState();
        this.setFlash(null, ['Your shifts have been unpublished!']);
      }).catch(function (err) {
        console.log(err)
      });
  }

  setBulkSelect = () => {
    this.setState({ bulkSelect: true });
  }

  setselectedShiftIds = (ids, shifts) => {
    this.setState({ selectedShiftIds: ids, selectedShifts: shifts })
  }

  removeAllEventHighlight = async () => {
    const highlightedEvents = document.querySelectorAll('.selected-event');
    for (let i = 0; i < highlightedEvents.length; i++) {
      highlightedEvents[i].classList.remove('selected-event');
    }
    this.setState({ selectedShiftIds: [] })
  }

  //reset the states
  resetState = () => {
    this.setState({
      start_time: null,
      end_time: null,
      days_of_week: null,
      edit: 'Add',
      employee: null,
      note: '',
      bulkSelect: false,
      selectedShiftIds: [],
      selectedShifts: []
    })
  }

  // create shift(s) at time slot specified by click & drag
  handleDateSelect = (selectInfo) => {
    this.setState({ start_time: selectInfo.start, end_time: selectInfo.end, edit: 'Add' })
  }
  //update the calendar date range
  updateCalDateRange = (start_time, end_time) => {
    this.setState({ calendarStartDate: start_time * 1000, calendarEndDate: end_time * 1000 })
  }

  handleSetEvent = (event) => {
    console.log(event);
    this.setState(event);
  }

  highlightEmployee = (netid) => {
    this.setState({ highlightedEmployee: netid })
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  closeFlash = () => {
    this.setState({ showFlash: false, error: false, success: false })
  }

  //show flash message
  flash = () => {
    if (!this.state.error && !this.state.success && !this.state.showFlash) {
      return <Flash type="hide" title='' messages={['']} closeFlash={this.closeFlash} />
    } else if (this.state.error && this.state.showFlash) {
      return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
    } else if (this.state.success && this.state.showFlash) {
      return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
    }
  }

  headerAndInstructions() {
    return <>
      <h4 className="title create-open-shifts-title">
        Create Open Shifts from {format(this.state.calendarStartDate, "LLL do")} to {format(this.state.calendarEndDate, "LLL do")}
      </h4>

      <span className="hover-undefined">
        <FontAwesomeIcon className="fa-question-circle fa-fw" icon={faQuestionCircle} />
      </span>
      <div className="hide">
        <p><span className="bold">Add shifts:</span> Enter the information on the form or drag time slots on the calendar.</p>
        <p><span className="bold">Update shift:</span> CLick on a shift and edit with the form or drag shift to different time slot on the calendar.</p>
        <p><span className="bold">Delete shift:</span> Click a shift and delete with the form's delete button.</p>
        <p><span className="bold">Delete Shifts:</span> Click to delete shifts for specific employees and time period.</p>
        <p><span className="bold">Select and Delete Shifts:</span> Click to bulk select shifts on the calendar to be deleted.</p>
      </div>
    </>

  }

    render(){
      // console.log("selected shifts:", this.state.selectedShifts)
        return <div>
        {this.flash()}
        {this.headerAndInstructions()}
  
        {/* full calendar to determine open shifts */}
        <div className="columns">
            <div className="column is-3">
              {this.state.bulkSelect ? <ShowShift selectedShifts={this.state.selectedShifts} />:
              <DraftForm data={this.props.data} start_time={this.state.start_time} end_time={this.state.end_time}
                users={this.props.data.users} calendarEndDate={this.state.calendarEndDate}
                shiftId={this.state.shiftId} employee={this.state.employee} group={this.props.data.group} note={this.state.note}
                locations={this.state.locations} location={this.state.location} addShiftsToDraft={this.addShiftsToDraft}
                updateDraftShift={this.updateDraftShift} deleteDraftShift={this.deleteDraftShift}
                edit={this.state.edit} checkError={this.checkError} setFlash={this.setFlash}
                resetFresh={this.resetState} updateCalDateRange={this.updateCalDateRange}
                publishSingle={this.publishSingle} unpublishSingle={this.unpublishSingle}
                colorCodes={this.state.colorCodes}
                highlightEmployee={this.highlightEmployee}
                removeAllEventHighlight={this.removeAllEventHighlight}
              />}
            </div>
            <DraftCalendar calendarRef={this.calendarRef} data={this.props.data} 
            handleDateSelect={this.handleDateSelect} calendarEndDate={this.state.calendarEndDate}
            calendarStartDate={this.state.calendarStartDate} updateCalDateRange={this.updateCalDateRange}
            handleSetEvent={this.handleSetEvent} colorCodes={this.state.colorCodes} 
            checkAvailability={this.checkAvailability} showFlash={() => this.setState({ showFlash: true})}
            resetState={this.resetState} getSchedule={this.props.getSchedule} setFlash={this.setFlash}
            setBulkSelect={this.setBulkSelect} bulkSelect={this.state.bulkSelect} selectedShiftIds={this.state.selectedShiftIds}
            setselectedShiftIds={this.setselectedShiftIds} selectedShifts={this.state.selectedShifts}
            removeAllEventHighlight={this.removeAllEventHighlight}
            checkError={this.checkError} highlightedEmployee={this.state.highlightedEmployee}
            />
        </div>
      </div>
    }
}

export default DraftIndex;