import React, { Component } from 'react';
import axios from 'axios';



class NewDraft extends Component {
  constructor(props) {
    super();
    this.state = { errors: false, success: false, minMinutes: "30", maxMinutes: "120" }
  }

  componentDidMount = () => {
    if (this.props.draft) {
      this.setState({ draft: this.props.draft });
    }
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    });
  }

  // change handler for input type date
  changeDateHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    // date comes from input in format "yyyy-mm-dd", so need to convert to javascript date
    let end = value.split("-")
    let date;
    if (name === "start_date"){
      // adjust month value from Jan=1 to Jan=0 and set time as 11:59:59 pm
      date = new Date(end[0], end[1] - 1, end[2], 0, 0, 0)
    } else if (name === "end_date"){
    // adjust month value from Jan=1 to Jan=0 and set time as 11:59:59 pm
      date = new Date(end[0], end[1] - 1, end[2], 23, 59, 59)
    }
    // console.log(date / 1000)
    this.setState({
      [name]: parseInt(date / 1000)
    })
  }

  timeOptions = () => {
    let final = []
    let minutes = 15
    while(minutes < 1440) {
      final.push(<option value={minutes.toString()} key={minutes}>{`${minutes} mins`}</option>)
      minutes += 15
    }
    return final
  }

  newDraftForm = () => {
    if (this.props.group) {
      return <div>
        {/* <h2 className="title my-6">New Schedule</h2> */}
        <h2 className="title">New Schedule</h2>
        <label className="label">Schedule Name</label>
        <div className="field">
          <div className="control">
            <input className="input" placeholder={this.state.draft ? "Copy of " + this.state.draft.scheduleName : "my schedule"} name="scheduleName" onChange={this.changeHandler} />
          </div>
        </div>

        <div className="field">
          <label className="label">Start Date</label>
          <div className="control">
            <input className="input" type="date" placeholder="yyyy-mm-dd" name="start_date" onChange={this.changeDateHandler} />
          </div>
        </div>

        <div className="field">
          <label className="label">End Date</label>
          <div className="control">
            <input className="input" type="date" placeholder="yyyy-mm-dd" name="end_date" onChange={this.changeDateHandler} />
          </div>
        </div>

        <div className="field">
          <label className="label">Min Shift Duration</label>
          <div className="select">
            <select name="minMinutes" value={this.state.minMinutes} onChange={this.changeHandler}>
              {this.timeOptions()}
            </select>
          </div>
        </div>

        <div className="field">
          <label className="label">Ideal Shift Duration</label>
          <div className="select">
            <select name="maxMinutes" value={this.state.maxMinutes} onChange={this.changeHandler}>
              {this.timeOptions()}
            </select>
          </div>
        </div>

        {/* <div className="field is-grouped"> */}
        <div>
          <Button  onClick={this.submitForm} value="calendar" title="Create Schedule" />
        </div>
      </div>
    }
  }

  submitForm = () => {
    if (this.state && this.props.group && this.state.scheduleName && this.state.start_date && this.state.end_date) {
      // add group, and schedule name to form data object

      if (this.state.start_date > this.state.end_date) {
        this.props.setFlash(['Please select an end date after the start date!']);
      } else {
        let formData = new FormData();
        formData.append("group", this.props.group)
        formData.append("scheduleName", this.state.scheduleName)
        formData.append("start_date", this.state.start_date)
        formData.append("end_date", this.state.end_date)
        formData.append("minMinutes", this.state.minMinutes)
        formData.append("maxMinutes", this.state.maxMinutes)


        axios.post("schedule/create_schedule",
          formData,
          { headers: { 'content-type': 'multipart/form-data' } }
        ).then(() => {
          //now we need a way to redirect
          if (this.state.draft){
            this.props.addShifts(this.state.scheduleName);
          } else {
            this.props.setFlash(false, ["Your schedule has been created!"]);
            this.props.redirectToNewDraft(this.state.scheduleName)
          }
        }).catch(function (err) {
          console.log(err)
        });
      }
    }
    else { // notify the user if the form is incomplete
      let missing = []
      if (!this.props.group) missing.push('group')
      if (!this.state.scheduleName) missing.push('schedule name')
      if (!this.state.start_date) missing.push('start date')
      if (!this.state.end_date) missing.push('end date')
      this.props.setFlash(missing, false);
    }
  }

  render() {
    return <div>
      {this.newDraftForm()}
    </div>
  }
}

/////// SUB COMPONENTS /////// 
// button sub-componet
const Button = props => {
  const handleClick = () => {
    if (props.onClick) {
      props.onClick(props.value);
    }
  }
  // return <button onClick={handleClick}>Speak</button>;
  return <p className="control">
    <input className="button is-link" type='button' onClick={handleClick} value={props.title} />
  </p>
}

export default NewDraft;