import React, { Component } from 'react';
import axios from 'axios';
import replacement from '../profile/duke.png'
import { format, isToday, isTomorrow } from "date-fns";
import './digitalSign.css'

const showImage = (self, student, shift) => {
  if (student?.netid) {
    let netid = student.netid;
    axios.get(
      `publicCalendars/profilePic/user/${netid}`
    ).then((response) => {
      let image = replacement;
      if (response.data !== '' && response.data) {
        image = response.config.baseURL + '/' + response.config.url;
      }
      if (shift === "next") {
        this.setState({ next_image: image });
      } else {
        let newCurrentShifts = self.state.currentShifts;
        let index = newCurrentShifts.indexOf(student);
        newCurrentShifts[index].image = image;
        self.setState({ currentShifts: newCurrentShifts });
      }
    }).catch((error) => {
      console.log(error)
    });
  }
}

const showXML = () => {
  axios.get("publicCalendars/digitalSign").then(response => {
  })
    .catch((error) => {
      console.log("error: ", error)
    });
}

const getCurrentStaffers = (self, group, location) => {
  axios.get("publicCalendars/digitalSign/current/" + group + "/" + location).then(response => {
    let data = response.data;
    if (data.length != 0) {
      data.forEach(d => showImage(self, d));
      self.setState({ currentShifts: data })
    }
  }).catch((error) => {
    console.log("error: ", error)
  });
}

const getCoverages = (allShifts) => {
  if (allShifts.length == 0) {return []};
  allShifts.sort((a, b) => {return a.start - b.start})
  let coverages = [];
  let start = allShifts[0].start;
  let end = allShifts[0].end;
  allShifts.forEach((s) => {
    if (s.start <= end) {
      end = end < s.end ? s.end : end;
    } else {
      coverages.push({start: start, end: end});
      start = s.start;
      end = s.end;
    }
  })
  coverages.push({start: start, end: end});
  return coverages;
}

const getCoverageAndNextShift = (self, group, location) => {
  let now = new Date();
  axios.get(`publicCalendars/find_time_group_location_fc/${now.setHours(0, 0, 0, 0) / 1000}/${now.setHours(24, 0, 0, 0) / 1000}/${group}/${location}`).then(response => {
    let coverages = getCoverages(response.data);
    let coverageStart, coverageEnd, nextShift;
    let now = Date.now();
    for (let i=0; i<coverages.length; i++){
      let c = coverages[i];
      if (c.start <= now && now <= c.end) {
        coverageStart = c.start;
        coverageEnd = c.end;
        if (i == coverages.length - 1) {
          getNextShift(self, coverageEnd, group, location);
        } else {
          nextShift = coverages[i + 1];
        }
        break;
      } else if (now < c.start) {
        nextShift = c;
        break;
      }
    }
    self.setState({
      coverageStart: coverageStart,
      coverageEnd: coverageEnd,
      nextShift: nextShift
    })
  });
}

const getNextShift = (self, coverageEnd, group, location) => {
  axios.get(`publicCalendars/digitalSign/day/${coverageEnd}/${group}/${location}`).then(response => {
    let coverages = getCoverages(response.data);
    self.setState({
      nextShift: coverages[0]
    })
  }).catch((error) => {
    console.log("error: ", error)
  });
}

  // format date. show minutes if time isn't on the hour. (example: 9AM, 6:30PM)
const formatHour = (date) => {
  if (format(date, "m") === "0") {
    return format(date, "haaa");
  } else {
    return format(date, "h:mmaaa");
  }
}

const formatDay = (time) => {
  if (isToday(time)) {
      return `Today`
  } else if (isTomorrow(time)) {
      return 'Tomorrow'
  } else {
      return format(time, "EEEE MMM. do");
  }
}

class DigitalSignOH extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverageStart: null,
      coverageEnd: null,
      nextShift: null
    }
  }

  componentDidMount = () => {
    // local
    //getCoverageAndNextShift(this, "colab student devs", "TEC");
    // dev
    //getCoverageAndNextShift(this, "CoLab Studios", "TEC" );
    // production
    getCoverageAndNextShift(this, "Colab Student Developer", "TEC Office Hours" );
  }

  displayHead = () => {
    let title = `Need Help?`;
    let style = {'--font-color': '#236768'};
    if (this.state.coverageStart && this.state.coverageEnd) {
      style = {'--top-color': '#22666a', '--bottom-color': '#435781', '--font-color': '#fefcf8'};
      title = `Office Hours`
    }
    return (
      <div id='digital-sign-head' style={style}>
        <div id='head-content'>
          <h3>INNOVATION CO-LAB STUDIOS OFFICE HOURS</h3>
          <h1>{title}</h1>
        </div>
      </div>
    )
  }

  displayBody = () => {
    let backgroundColor = {'--top-color': '#22666a', '--bottom-color': '#435781'};
    if (this.state.coverageStart && this.state.coverageEnd) {
      backgroundColor = {'--top-color': '#fefcf8', '--bottom-color': '#fefcf8'}
    }
    return (
      <div id='digital-sign-body' style={backgroundColor}>
        <div id='body-content'>
          {this.displayCoverageOrText()}
          {this.displayNextShift()}
        </div>
      </div>
    )
  }

  displayCoverageOrText = () => {
    let fontColor = {};
    let text = <p className='main' id='body-blob'>Find us in the Rotunda in the rainbow chairs!</p>;
    let shift;
    if (this.state.coverageStart && this.state.coverageEnd) {
      fontColor = {'--main-color': '#236768', '--secondary-color': '#236768'};
      shift = [
        <h2 className="secondary">Current Staffed Hours</h2>,
        <h1 id='oh-time' className='main'>{formatHour(this.state.coverageStart)} - {formatHour(this.state.coverageEnd)}</h1>
      ];
    } else {
      text = '';
      shift = [
        <h2 className="secondary">{`Currently Unstaffed :(`}</h2>,
        <p className='main'><span className='slim'>In the meantime, check out</span> colab.duke.edu <span className='slim'>for other resources or to contact us</span></p>
      ];
    }
    return (
      <div id='current-shift' style={fontColor}>
        {text}
        <div id='OH-shift'>{shift}</div>
      </div>
    )
  }

  displayNextShift = () => {
    let shift;
    let fontColor = {'--font-color': '#9dbab9'};
    if (this.state.nextShift){
      return (
        <div id='next-shift' style={fontColor}>
          <p>Next Shift</p>
          <h2>{formatDay(this.state.nextShift.start)}, {formatHour(this.state.nextShift.start)} - {formatHour(this.state.nextShift.end)}</h2>
        </div>
      )
    }
  }

  render() {
    return (<div id="sign-container">
      {this.displayHead()}
      {this.displayBody()}
    </div>);
  }
}

class DigitalSignOHLarge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShifts: null,
      coverageStart: null,
      coverageEnd: null,
      nextShift: null
    }
  }

  componentDidMount = () => {
    // local
    //getCurrentStaffers(this, "colab student devs", "TEC");
    //getCoverageAndNextShift(this, "colab student devs", "TEC");
    // dev
    //getCurrentStaffers(this, "CoLab Studios", "TEC");
    //getCoverageAndNextShift(this, "CoLab Studios", "TEC");
    // production
    getCurrentStaffers(this, "Colab Student Developer", "TEC Office Hours");
    getCoverageAndNextShift(this, "Colab Student Developer", "TEC Office Hours");
  }

  displayHead = () => {
    let title = `Sorry we missed you...`;
    let style = {'--font-color': '#236768'};
    if (this.state.currentShifts) {
      style = {'--top-color': '#22666a', '--bottom-color': '#435781', '--font-color': '#fefcf8'};
      title = `Ask us for help!`
    }
    return (
      <div id='digital-sign-head' style={style}>
        <div id='head-content'>
          <h3>INNOVATION CO-LAB STUDIOS OFFICE HOURS</h3>
          <h1>{title}</h1>
        </div>
      </div>
    )
  }

  displayBodyNew = () => {
    if (this.state.currentShifts) {
      let backgroundColor = {'--top-color': '#fefcf8', '--bottom-color': '#fefcf8'};
      let fontColor = {'--main-color': '#236768', '--secondary-color': '#236768'};
      return (
        <div id='digital-sign-body' style={backgroundColor}>
          <div id='body-content'>
            <div id='current-shift' style={fontColor}>
              <p className='main' id="body-blob">Our student consultants are available to answer questions about your coding projects or other technology needs.</p>
              <div id='OH-shifts'>
                {this.displayCoverage()}
                {this.displayNextShiftStacked()}
              </div>
            </div>
            {this.displayStaffers()}
          </div>
        </div>
      )
    } else {
      let backgroundColor = {'--top-color': '#22666a', '--bottom-color': '#435781'};
      let fontColor = {};
      return (
        <div id='digital-sign-body' style={backgroundColor}>
          <div id='body-content'>
            <div id='current-shift' style={fontColor}>
              <p className='main' id="body-blob">Unfortunately, our Office Hours staff are not available right now. Check back later for help on your coding projects or other technology needs.</p>
              <div id='OH-shift'>
              <p className='main'><span className='slim'>In the meantime, check out</span> colab.duke.edu <span className='slim'>for other resources or to contact us</span></p>
              </div>
              {this.displayNextShift()}
            </div>
          </div>
        </div>
      )
    }
  }

  displayCoverage = () => {
    if (this.state.coverageStart && this.state.coverageEnd) {
      return (
        <div id='OH-shift'>
          <h2 className="secondary">Current Staffed Hours</h2>
          <h1 id='oh-time' className='main'>{formatHour(this.state.coverageStart)} - {formatHour(this.state.coverageEnd)}</h1>
        </div>
      )
    }
  }

  displayNextShiftStacked = () => {
    let fontColor = {'--font-color': '#9dbab9'};
    if (this.state.nextShift){
      return (
        <div id='next-shift' style={fontColor}>
          <p>Next Shift</p>
          <h2>{formatDay(this.state.nextShift.start)}</h2>
          <h2>{formatHour(this.state.nextShift.start)} - {formatHour(this.state.nextShift.end)}</h2>
        </div>
      )
    }
  }

  displayNextShift = () => {
    let fontColor = {'--font-color': '#9dbab9'};
    if (this.state.nextShift){
      return (
        <div id='next-shift' style={fontColor}>
          <p>Next Shift</p>
          <h2>{formatDay(this.state.nextShift.start)}, {formatHour(this.state.nextShift.start)} - {formatHour(this.state.nextShift.end)}</h2>
        </div>
      )
    }
  }

  displayStaffers = () => {
    let bios = [];
    let colors = ['#eaaed0', '#efdbb1', '#eaedb1', '#c5eed9', '#c1d3f1', '#aea6f8', '#e0c2f4'];
    let colorIndex = 0;
    this.state.currentShifts.forEach((s) => {
      let nameArray = s.user_name.split(" ");
      if (s.clocked_in) {
        bios.push(<div className='OH-staffer-bio'>
          <img className='OH-staffer-photo' src={s.image} alt="person on shift" />
          <p className='secondary staffer-name'>{nameArray[0]}</p>
        </div>)
      } else {
        bios.push(<div className='OH-staffer-bio'>
          <svg className='placeholder-photo' style={{'--color': colors[colorIndex]}}></svg>
          <p className='secondary staffer-name'>{nameArray[0]}</p>
        </div>)
      }
      colorIndex = colorIndex == 6 ? 0 : (colorIndex + 1);
    })

    return (<div id='staffers'>
      <p className='main'>Current Staff on Duty</p>
      <div id='OH-staffer-bios'>
        {bios}
      </div>
    </div>)
  }

  //<img className='OH-staffer-photo placeholder-photo' style={{'--color': colors[colorIndex]}} alt="person on shift" />



  render() {
    return (<div id="sign-container-large">
      {this.displayHead()}
      {this.displayBodyNew()}
    </div>);
  }
}

export {DigitalSignOH, DigitalSignOHLarge};