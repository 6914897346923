import React, { Component } from 'react';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';
import format from 'date-fns/format';
import Flash from '../../common/flash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faClipboard } from '@fortawesome/free-solid-svg-icons';

class ShiftModal extends Component {
  constructor(props) {
    super();
    this.state = {
      showModal: false, showNoteModal: false, clocked_in: null, clocked_out: null, start_time: null,
      end_time: null, success: null, error: null, users: null, employee: null,
      completed: false, selected: [], note: ''
    }
  }

  componentDidMount() {
    if (this.props.shift) {
      let shift = this.props.shift;
      let clocked_in = shift.clocked_in ? shift.clocked_in : "";
      let clocked_out = shift.clocked_out ? shift.clocked_out : "";
      let status = (shift.status && shift.status === "completed") ? true : false;
      // let note = shift.note ? shift.note : "";
      this.setState({
        start_time: shift.start_time,
        end_time: shift.end_time,
        clocked_in: clocked_in,
        clocked_out: clocked_out,
        completed: status,
        employee: this.props.shift.employee,
        note: shift.note
      })
    } else if (this.props.showModal) {
      this.openModal();
      this.setState({
        start_time: "",
        end_time: ""
      })
      this.getUsers();
    }
  }

  changeTimeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: Date.parse(value) / 1000 })
  }

  deleteRecord = () => {
    if (window.confirm('Are you sure you want to delete this record?')) {
      axios.delete(`shifts/delete/${this.props.shift._id}/${this.props.shift.adhoc}`).then((response) => {
        this.props.getShifts();
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  openModal = () => {
    this.setState({ showModal: true })
  }

  openNoteModal = (event) => {
    this.setState({ showNoteModal: true });
    // this.props.openNoteModal();
  }

  closeModal = () => {
    this.setState({ showModal: false, showNoteModal: false, selected: [] })
  }

  getUsers = () => {
    let self = this;
    axios.get("users/employee_list").then((response) => {
      console.log("EMPS", response.data)
      self.setState({ users: response.data })
    }).catch((error) => {
      console.log(error)
    });
  }

  //populate dropdown for users
  mapUsers = () => {
    let options = []
    if (this.state.users) {
      this.state.users.map((user) =>
        options.push(<option key={user.netid} value={user.netid}> {user.display_name}</option>)
      )
    }
    return (options)
  }

  // generic change handler
  changeHandler = (event) => {
    const name = event.target.name;
    let data;
    if (name === "completed") {
      data = event.target.checked;
    } else {
      data = this.state.users.filter(obj => { return obj.netid === event.target.value })[0];
    }
    this.setState({
      [name]: data
    });
  }

  changeNoteHandler = (event) => {
    const value = event.target.value;

    this.setState({ note: value })
  }

  setSelected = (event) => {
    this.setState({ selected: event })
  }

  formModal = () => {
    if (this.state.showModal) {
      let editShift;
      let addShift;
      let closeModal;
      let clockInAndOut;
      let mapUsers;

      //multiple select option
      const options = [];
      if (this.state.users) {
        this.state.users.forEach((user) => {
          options.push({ label: user.displayName, value: user.netid, employee: user })
        })
      }

      if (this.props.shift) {
        editShift = <p className="modal-card-title">Adjust Time for: {format(this.props.shift.start_time * 1000, "M/d/y")}</p>;
        closeModal = <button onClick={this.closeModal.bind(this)} className="delete" aria-label="close"></button>;
        clockInAndOut = <div className="field">
          <label className="label">Clock In</label>
          <div className="control">
            <input
              className="input"
              type="datetime-local"
              name="clocked_in"
              value={this.state.clocked_in ? format(this.state.clocked_in * 1000, 'yyyy-MM-dd\'T\'HH:mm') : ""}
              onChange={this.changeTimeHandler}
            />
          </div>
          <label className="label">Clock Out</label>
          <div className="control">
            <input
              className="input"
              type="datetime-local"
              name="clocked_out"
              value={this.state.clocked_out ? format(this.state.clocked_out * 1000, 'yyyy-MM-dd\'T\'HH:mm') : ""}
              onChange={this.changeTimeHandler}
            />
          </div>
          <label className="label">Note</label>
          <div className="field">
            <div className="control">
              <input
                className="input"
                name="note"
                value={this.state.note ? this.state.note : ""}
                onChange={this.changeNoteHandler}
              />
            </div>
          </div>
          <label className="label"> Completed <input type="checkbox" name="completed" onChange={this.changeHandler} checked={this.state.completed} /></label>
        </div>
      } else {
        addShift = <p className="modal-card-title">Add a Shift</p>;
        closeModal = <button onClick={this.props.closeModal} className="delete" aria-label="close"></button>;
        mapUsers = <div className="field">
          <label className="label">Employee</label>
          <div className="control">
            <MultiSelect
              options={options}
              value={this.state.selected}
              onChange={this.setSelected}
              labelledBy="Select"
            />
          </div>
        </div>
      }
      return <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            {editShift}
            {addShift}
            {closeModal}
          </header>

          <section className="modal-card-body">

            {this.flash()}

            <form>

              <div className="field">
                <label className="label">Start Time</label>

                <div className="control">
                  <input
                    className="input"
                    type="datetime-local"
                    name="start_time"
                    value={this.state.start_time ? format(this.state.start_time * 1000, 'yyyy-MM-dd\'T\'HH:mm') : ""}
                    onChange={this.changeTimeHandler}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">End Time</label>
                <div className="control">
                  <input
                    className="input"
                    type="datetime-local"
                    name="end_time"
                    value={this.state.end_time ? format(this.state.end_time * 1000, 'yyyy-MM-dd\'T\'HH:mm') : ""}
                    onChange={this.changeTimeHandler}
                  />
                </div>
              </div>

              {clockInAndOut}
              {mapUsers}
            </form>
          </section>
          <footer className="modal-card-foot">
            <div className="control">
              <input className="button is-link" type='submit' onClick={this.submitForm} />
            </div>
          </footer>
        </div>
      </div>
    }
  }

  drawNoteModal = () => {
    if (this.state.showNoteModal && this.state.note) {
      return (
        <div className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <button onClick={this.closeModal.bind(this)} className="delete" aria-label="close"></button>
            </header>

            <section className="modal-card-body">

              {this.flash()}

              <div className="field">
                <label className="label">Note</label>
                <p>{this.state.note ? this.state.note : ""}</p>
              </div>

            </section>

            <footer className="modal-card-foot">
            </footer>
          </div>
        </div>
      )
    }
  }
  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  closeFlash = () => {
    this.setState({ error: false, success: false })
  }

  //show error message
  flash = () => {
    if (this.state.error) {
      return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
    } else if (this.state.success) {
      return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
    }
  }

  // log user activity
  logAction = (reason) => {
    axios.get("/users/log/" + reason)
      .then((response) => {
        console.log(response.data);
      }).catch((error) => {
        console.log(error)
      });
  }


  //submit edits from the modal
  submitForm = (event) => {
    event.preventDefault();

    if (this.state.start_time >= this.state.end_time) {
      this.setState({ error: ["Please have start time before end time!"] })
    } else if (!this.props.showModal && this.state.clocked_in && this.state.clocked_out && (this.state.clocked_in >= this.state.clocked_out)) {
      this.setState({ error: ["Please have clock in before clock out!"] })
    } else if (this.state.selected.length < 1 && !this.props.shift) {
      this.setState({ error: ["Please select an employee!"] })
    } else {

      if (!this.props.shift) {
        this.state.selected.forEach(select => {
          let shift = {
            start_time: this.state.start_time,
            end_time: this.state.end_time,
            employee: select.employee,
            group: select.employee.group[0],
            note: this.state.note
          }
          axios.post("shifts/createAdhoc", shift
          ).then((response) => {
            this.props.closeModal();
            this.props.getShifts();
            this.logAction("added a new shift");
          }).catch(function (err) {
            console.log(err)
          });
        });
      } else {
        let formData = new FormData();

        formData.append("start_time", this.state.start_time);
        formData.append("end_time", this.state.end_time);
        if (this.state.clocked_in) {
          formData.append("clocked_in", this.state.clocked_in);
          formData.append("clock_in_ip_in_range", true);
        }
        if (this.state.clocked_out) {
          formData.append("clocked_out", this.state.clocked_out);
          formData.append("clock_out_ip_in_range", true);
        }
        if (this.state.completed) {
          formData.append("status", "completed");
        } else {
          formData.append("status", "incomplete");
        }
        if (this.state.note) {
          formData.append("note", this.state.note);
        }
        axios.put(
          "shifts/update_hours/" + this.props.shift._id,
          formData,
          { headers: { 'content-type': 'multipart/form-data' } }
        ).then((response) => {
          this.closeModal();
          this.props.getShifts();
          this.logAction("edited a shift");
        }).catch(function (err) {
          console.log(err)
        });
      }
    }
  }

  render() {
    if (this.props.showModal) {
      return (<div>{this.formModal()}</div>)
    } else {
      let editPermission = localStorage.getItem("role") !== "employee" || this.props.shift.adhoc;
      return (
        <td>
          <div>
            {!editPermission ? "-" : ''}
            {this.props.shift.adhoc ? <span className="tag is-primary space">Self-report</span> : ''}
            <span hidden={!editPermission}><FontAwesomeIcon className="clickable" icon={faEdit} onClick={this.openModal.bind(this)} /> </span>
            <span hidden={!editPermission}><FontAwesomeIcon className="clickable" icon={faTrash} onClick={this.deleteRecord.bind(this)} /></span>
            <span hidden={this.state.note ? false : true} style={{ paddingLeft: '5px' }}><FontAwesomeIcon className="clickable" icon={faClipboard} onClick={this.openNoteModal.bind(this)} /></span>
          </div>
          {this.formModal()}
          {this.drawNoteModal()}
        </td>
      )
    }
  }
}

export default ShiftModal;