import React, { Component } from 'react';
import HoursIndex from './hours/hoursIndex';
import UserActivity from './userActivity';
import { checkGroups } from '../helpers/checkGroupName';
import Flash from '../common/flash';
import "./hours.css"
import MessageLog from './messageLog';

class HoursReportsIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      render: 'hour'
    };
  }

  componentDidMount() {
    //check for correct groups (creates flash error if group name has been changed)
    checkGroups().then((response) => {
      console.log(response)
      if (response.error) { this.setFlash(("SYS!:"+response.error), false); }
    });
  }

  
  //change the render tab name
  handleClick(compName, e) {
    this.setState({ render: compName });
  }

  //rerender the pagae
  switchTab() {
    switch (this.state.render) {
      default: return <HoursIndex />
      case 'activity': return <UserActivity />
      case 'message': return <MessageLog />
    }
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  //show error/success message
  flash = () => {
    let ret = [];
    
    if (this.state.success) {
      ret.push(<Flash key="success" type="success" title="Success" messages={["Your edit has been saved!"]} closeFlash={() => this.setState({success: false})} />);
    }

    if(this.state.error) {
      let errTitle ="Incomplete Form";
      let errMessage = this.state.error.toString();

      //check for systen error
      if (errMessage.slice(0,5) === "SYS!:") {
        errTitle="System Error";
        errMessage = errMessage.slice(5,errMessage.length);
      }

      ret.push(
        <Flash key="error" type="error" title={errTitle} messages={[errMessage]} closeFlash={() => this.setState({error: false})} />
      );

    }

    return ret;
  };

  render() {
    if (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "supervisor") {
      return (
        <div className="container">
          {this.flash()}
          <div className="tabs">
            <ul>
              <li className={this.state.render === 'hour' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'hour')}><a href="#/">Hours</a></li>
              <li className={this.state.render === 'activity' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'activity')}><a href="#/">User Activities</a></li>
              {localStorage.getItem('role') === "admin"?<li className={this.state.render === 'message' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'message')}><a href="#/">Message Log</a></li>:''}
            </ul>
          </div>
          {this.switchTab()}
        </div>

      )
    } else {
      return (
        <div className="container">
          <HoursIndex />
        </div>
      )
    }
  }
}

export default HoursReportsIndex;
