import React, { Component } from 'react';
import axios from 'axios';
import EmployeeView from "./employeeView";
import SupervisorView from "./supervisorView";
import startOfWeek from 'date-fns/startOfWeek'
import subWeeks from 'date-fns/subWeeks'
import format from 'date-fns/format'
import toDate from 'date-fns/toDate'
import getUnixTime from 'date-fns/getUnixTime'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import getDate from 'date-fns/getDate'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import ShiftModal from "./shiftModal";
import { Link } from "react-router-dom";
import Spinner from '../../common/loader';

class HoursIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      data: null,
      start_date: startOfDay(subWeeks(startOfWeek(new Date()), 2)),
      end_date: endOfDay(new Date()),
      choose_date: false,
      group: false,
      showModal: false,
      type: "all"
    }
  }

  componentDidMount = () => {
    this.getShifts();
  }

  sortTime = (data) => {
    return data.shifts.sort((a, b) => {
      if (a.start_time < b.start_time || (a.start_time === b.start_time && a.end_time < b.end_time))
        return -1;
      if (a.start_time > b.start_time || (a.start_time === b.start_time && a.end_time > b.end_time))
        return 1;
      return 0;
    });
  }

  getShifts = () => {
    let self = this;
    let start = getUnixTime(new Date(this.state.start_date));
    let base_date = this.state.end_date;
    let end = new Date(getYear(base_date), getMonth(base_date), getDate(base_date), 23, 59, 59);
    end = getUnixTime(end);
    let group = this.state.group ? this.state.group : "all";
    if (localStorage.getItem('role') === 'employee') {
      axios.get(`shifts/employee_hours/${start}/${end}/${localStorage.getItem('group')}`).then((response) => {
        this.sortTime(response.data);
        self.setState({ data: response.data })
      }).catch((error) => {
        console.log(error)
      });
    } else if (localStorage.getItem('role') === 'supervisor' || localStorage.getItem('role') === 'admin') {
      axios.get(`shifts/supervisor_hours/${start}/${end}/${group}`).then((response) => {
        response.data.map(employee => this.sortTime(employee.details));
        self.setState({ data: response.data })
      }).catch((error) => {
        console.log(error)
      });
    }
    if (this.state.choose_date) {
      this.toggleShowDatePicker()
    }
  }

  changeDateHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    var date_parts = value.split("-")
    var date = toDate(new Date(date_parts[0], date_parts[1] - 1, date_parts[2], 0, 0, 0))

    this.setState({
      [name]: date
    }, () => this.getShifts());
  }

  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value
    }, () => this.getShifts());

  }

  toggleShowDatePicker = () => {
    this.setState({ choose_date: !this.state.choose_date })
  }

  groupOptions = () => {
    var options = []
    let groups = localStorage.getItem('group').split(",");
    groups.forEach((group) =>
      options.push(
        <option key={group} className="capitalize-me" value={group}>{group}</option>
      )
    )
    return options
  }

  showModal = () => {
    this.setState({ showModal: true })
  }

  showNoteModal = () => {
    this.setState({ showNoteModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false, showNoteModal: false })
  }

  drawHeader = () => {
    if (this.state.data) {
      let addShiftButton;
      let addTypeFilter;
      let downloadPdfBtn;
      let base_date = this.state.end_date;
      let end = new Date(getYear(base_date), getMonth(base_date), getDate(base_date) + 1, 0, 0, 0);
      if (localStorage.getItem('role') === 'supervisor' || localStorage.getItem('role') === 'admin') {
        addShiftButton =
          <div className="control">
            <label className="label">Action</label>
            <input className="button is-light space" onClick={this.showModal} type='button' value="Add a Shift" />
          </div>
        downloadPdfBtn =
          <div className="control">
            <label className="label">Action</label>
            <Link to={`/shiftr-document/${this.state.start_date}/${end}/${this.state.group ? this.state.group : "all"}`} className="navbar-item nav-link">Download Hours Report</Link>
          </div>
        addTypeFilter =
          <div className="control">
            <label className="label">Type</label>
            <div className="select" onChange={this.changeHandler}>
              <select defaultValue="all" name="type">
                <option key="all" className="capitalize-me" value="all">All Shifts</option>
                <option key="complete" className="capitalize-me" value="complete">Only Completed</option>
                <option key="incomplete" className="capitalize-me" value="incomplete">Only in-completed</option>
                <option key="outofrange" className="capitalize-me" value="outofrange">Out of Range</option>
              </select>
            </div>
          </div>

      }
      return (
        <div className="field is-grouped">
          <div className="control">
            <label className="label">Start Date</label>
            <input
              className="input"
              type="date"
              value={format(this.state.start_date, "yyyy-MM-dd")}
              name="start_date"
              onChange={this.changeDateHandler}
            />
          </div>
          <div className="control">
            <label className="label">End Date</label>
            <input
              className="input"
              type="date"
              value={format(this.state.end_date, "yyyy-MM-dd")}
              name="end_date"
              onChange={this.changeDateHandler}
            />
          </div>
          <div className="control">
            <label className="label">Group</label>
            <div className="select" onChange={this.changeHandler}>
              <select defaultValue="all" name="group">
                <option key="all" className="capitalize-me" value="all">All Groups</option>
                {this.groupOptions()}
              </select>
            </div>
          </div>
          {addTypeFilter}
          {addShiftButton}
          {downloadPdfBtn}
        </div>
      );
    }
  }

  drawHours = () => {
    if (localStorage.getItem('role') === 'employee') {
      return <EmployeeView data={this.state.data} getShifts={this.getShifts}
        startDate={this.state.start_date} endDate={this.state.end_date} />
    } else if (localStorage.getItem('role') === 'supervisor' || localStorage.getItem('role') === 'admin') {
      return <SupervisorView getShifts={this.getShifts} data={this.state.data} type={this.state.type}
        startDate={this.state.start_date} endDate={this.state.end_date} />
    }
  }

  render() {
    return (
      <div className="container">
        <h1 className="title">Hours</h1>
        {this.state.data ? <>
          {this.drawHeader()}
          {this.drawHours()}
          {this.state.showModal ?
            <ShiftModal showModal={this.state.showModal} closeModal={this.closeModal} getShifts={this.getShifts} /> : null}</>
          : <Spinner />}
      </div>
    );
  }
}

export default HoursIndex