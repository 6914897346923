import React, { Component } from "react";
import format from "date-fns/format";
import axios from "axios";
import distinctColors from "distinct-colors";

import Flash from "../common/flash";
import "../../css/banner.css";
import { checkGroups } from "../helpers/checkGroupName";

class Banner extends Component {
  constructor(props) {
    super();
    this.state = {
      groupColors: null,
      locationColors: null,
      checkedList: null,
      checkedLocations: null,
      bannerRows: null,
      todayStart: new Date().setHours(7, 0, 0, 0),
      todayEnd: new Date().setHours(26, 0, 0, 0),
      coverageStart: null,
      coverageEnd: null,
      isIframe: null,
      screenReaderText: null
    };
  }

  componentDidMount = () => {
    checkGroups().then((response) => {
      if (response.error) { this.setFlash(("SYS!:"+response.error), false); }
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const group = urlParams.has("group")
      ? urlParams.get("group").split(",")
      : false;
    const location = urlParams.has("location")
      ? urlParams.get("location").split(",")
      : false;
    const timeframe = urlParams.has("timeframe")
      ? urlParams.get("timeframe").split(",")
      : false;
    //if the banner is showing on a shared link
    if (location && timeframe && group) {
      this.setState({ 
        isIframe: true,
        todayStart: new Date().setHours(timeframe[0], 0, 0, 0),
        todayEnd: new Date().setHours(timeframe[1], 0, 0, 0),
        checkedList: group,
        checkedLocations: location
      });
      this.assignLocationColorsOnly(location);
    } else {
      let localGroup = localStorage.getItem("group")
        ? localStorage.getItem("group").split(",")
        : [];
      axios
        .get("shifts/all_groups")
        .then((response) => {
          let findGroup = localGroup.filter((oneGroup) =>
            response.data.includes(oneGroup)
          );
          this.setState({ checkedList: findGroup, isIframe: false });
          this.assignGroupColors(localGroup, findGroup);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  updateBannerRows = async () => {
    let bannerRows = [];
    let screenReaderText = {};
    await axios.get(
      "publicCalendars/find_time_group_location_fc/" +
      this.state.todayStart / 1000 +
      "/" +
      this.state.todayEnd / 1000 +
      "/" +
      this.state.checkedList +
      "/" +
      this.state.checkedLocations
    ).then ((response) => {
      response.data.sort((a, b) => a.start - b.start );
      let classifiedShifts = this.classifyShiftsIntoGroups(response.data);
      for (let i = 0; i < this.state.checkedLocations.length; i++) {
        let loc = this.state.checkedLocations[i];
        if (loc in classifiedShifts) {
          let [rowFlexboxes, screenReader] = this.createShiftFlexboxes(classifiedShifts[loc]);
          screenReaderText[loc] = "Shifts in " + loc + ":" + screenReader;
          bannerRows.push(
            <div className="banner-row" key={i}>
              {rowFlexboxes}
            </div>
          )
        } else {
          screenReaderText[loc] = loc + ": No shifts today"
          bannerRows.push(
            <div className="banner-row"  key={i}>
              <div className="banner-blocks" style={{flex: 1}}></div>
            </div>
          )
        }
      }
    }).catch ((error) => {
      console.log(error);
    })
    this.setState({bannerRows: bannerRows, screenReaderText: screenReaderText});
  }

  // sets groupColors state (list of dictionaries in format {group: 'name', color: 'hex code'})
  // sets locationColors state (list of dictionaries in format {location: 'name', color: [array of chroma-js objects from distinct-colors]})
  assignGroupColors = async (groups, checkedList) => {
    let colors = [
      "#F5737B",
      "#F7A7FF",
      "#81A0FB",
      "#FFA5AA",
      "#A8BEFF",
      "#9F93F7",
      "#BCB4FB",
    ];
    let groupColors = {};
    let allLocations = [];
    let checkedLocations = [];
    for (let i = 0; i < groups.length; i++) {
      let group = groups[i].trim();
      let response = await axios.get("publicCalendars/find/" + group);
      if (response.status !== 200) {
        console.log(
          "Did not find this group:",
          group,
          ", got error:",
          response.status
        );
      } else if (response.data) {
        let locations;
        if (response.data.locations) {
          locations = response.data.locations;
          if (checkedList.includes(response.data.name)){
            response.data.locations.forEach((loc) => {
              if (!allLocations.includes(loc)){
                allLocations.push(loc);
                checkedLocations.push(loc);
              }
            });
          } else {
            response.data.locations.forEach((loc) => {
              if (!allLocations.includes(loc)){
                allLocations.push(loc);
              }
            });
          }
        }
        groupColors[group] = {color: colors[i], locations: locations};
      }
    }

    //SET LOCATION COLORS
    let pallette = distinctColors({ count: allLocations.length, lightMin: 30 }); //location colors assigned here
    let locationColors = {};
    for (let i = 0; i < allLocations.length; i++) {
      locationColors[allLocations[i]] = pallette[i];
      //locationColors.push({ location: allLocations[i], color: pallette[i] });
    }

    this.setState({
      checkedLocations: checkedLocations,
      groupColors: groupColors,
      locationColors: locationColors
    }, () => {
      this.updateBannerRows();
    });
  };

  assignLocationColorsOnly = (locations) => {
    let locationColors = {};
    let pallette = distinctColors({ count: locations.length }); //location colors assigned here

    for (let i = 0; i < locations.length; i++) {
      locationColors[locations[i]] = pallette[i];
    }

    this.setState({ locationColors: locationColors }, () => {this.updateBannerRows()});
  }

  classifyShiftsIntoGroups = (shifts) => {
    let classifiedShifts = {};
    let coverageEnd = null;
    let coverageStart = null;
    if (shifts.length !== 0) {
      coverageEnd = 0;
      coverageStart = shifts[0].start;
      shifts.forEach((shift) => {
        coverageEnd = shift.end > coverageEnd ? shift.end : coverageEnd;
        if (shift.extendedProps.location in classifiedShifts) {
          classifiedShifts[shift.extendedProps.location].push(shift);
        } else {
          classifiedShifts[shift.extendedProps.location] = [shift];
        }
      })
    }
    this.setState({ coverageEnd: coverageEnd, coverageStart: coverageStart });
    return classifiedShifts;
  }

  // Creates a row of flexboxes for the banner
  // The widths of the flexboxes are calculated using the ratio of a shift's duration over the duration of the entire day
  // width = lengthOfShift / lengthOfDay
  createShiftFlexboxes = (shiftsArray) => {
    // Sets the start and end of shift coverage times (when only one location is selected)
    let screenReaderText = "";
    let shifts = [...shiftsArray, {"start": this.state.todayEnd, "end": this.state.todayEnd}];
    let key = 0;
    // Length of an entire day in milliseconds (epoch time)
    // Because we display an extra time block on the banner after closing time, 
    // we add an extra hour to todayLength so the proportions are correct
    let todayLength = this.state.todayEnd - this.state.todayStart + 3600000;

    // Creating an array of flexboxes representing a row on the banner
    // First element in the array is the blank space between the beginning of the day and the start of the first shift
    let shiftFlexboxes = [<div className="banner-blocks" key={key} style={{flex: ((shifts[0].start-this.state.todayStart)/todayLength)}}></div>];
    let color = this.state.locationColors[shiftsArray[0].extendedProps.location];
    for (let i = 0; i < shifts.length - 1; i++){
      let shiftEnd = shifts[i].end;
      let shiftStart = shifts[i].start;
      while (shifts[i+1] && shifts[i+1].start <= shiftEnd) {
        shiftEnd = shifts[i+1].end <= shiftEnd ? shiftEnd : shifts[i+1].end;
        i += 1;
      }
      let shiftRatio = (shiftEnd - shiftStart) / todayLength;
      key += 1;
      shiftFlexboxes.push(<div className="banner-blocks" key={key} style={{flex: shiftRatio, background: color, zIndex: 10}}></div>)
      let blankRatio = (shifts[i+1].start - shiftEnd) / todayLength;
      key += 1;
      shiftFlexboxes.push(<div className="banner-blocks" key={key} style={{flex: blankRatio}}></div>)
      screenReaderText += " " + format(new Date(shiftStart), "h:mm aa") + " to " + format(new Date(shiftEnd), "h:mm aa") + ","
    }
    
    return [shiftFlexboxes, screenReaderText];
  }

  drawBanner = () => {
    if (this.state.bannerRows) {
      let numberOfLocations = this.state.bannerRows.length;
      if (numberOfLocations === 0) {
        return (
          <h1 className="title my-5">
            No locations or groups specified
          </h1>
        )
      } else if (numberOfLocations === 1) {
        return (
          <div>
            <span className="is-sr-only">These are today's shifts in {this.state.screenReaderText[this.state.checkedLocations[0]]}</span>
            {this.state.coverageStart ? 
              <h1 className="title my-5" aria-hidden= "true">
                Today's Hours: {format(new Date(this.state.coverageStart), "h:mm aaa")} to {format(new Date(this.state.coverageEnd), "h:mm aaa")}
              </h1> :
              <h1 className="title my-5">
                No shifts today
              </h1>
            }
            <div className="banner-container" aria-hidden="true">
              {this.drawTimeTicks()}  
              <div className="banner-row"></div>
              {this.state.bannerRows}
            </div>
          </div>
        )
      } else {
        return (
          <div className="columns is-gapless">
            {this.drawBannerLocations()}
            <div className="column">
              <div className="banner-container" aria-hidden= "true">
                {this.drawTimeTicks()}            
                <div className="banner-row"></div>
                {this.state.bannerRows}
              </div>
            </div>
          </div>
        )
      }
    }
  }

  drawBannerLocations = () => {
    let locations = this.state.checkedLocations.map((location, index) => 
        <div className="banner-row" key={index}>
          <span aria-hidden="true">{location}</span>
          <span className="is-sr-only">{this.state.screenReaderText[location]}</span>
        </div>)

    return (
      <div className="column is-2">
        <div className="banner-row">
          <span className="is-sr-only">The following are our open locations and their respective shifts for today:</span>
        </div>
        {locations}
      </div>
    )
  }

  drawTimeTicks = () => {
    let hourTicks = [];
    for (let i = this.state.todayStart; i <= this.state.todayEnd; i += 3600000) {
      hourTicks.push(<div className="banner-times" key={i}>{format(new Date(i), "haaa")}</div>)
    }

    return (
      <div className="banner-row-time">
        {hourTicks}
      </div>
    )
  }

  getLink = () => {
    let group = this.state.checkedList.join();
    let location = this.state.checkedLocations.join();
    let midnight = new Date().setHours(0, 0, 0, 0);
    let timeframe = `${(this.state.todayStart - midnight)/3600000},${(this.state.todayEnd - midnight)/3600000}`;
    let text = `${window.location.host}/bannerIframe/?group=${group}&location=${location}&timeframe=${timeframe}`;
    navigator.clipboard.writeText(text).then(
      () => {
        this.setState({ success: ["Link copied to clipboard!"] });
      },
      function (err) {
        this.setState({ error: [err] });
      }
    );
  };

  linkButton() {
    if (
      localStorage.getItem("role") === "admin" ||
      localStorage.getItem("role") === "supervisor"
    ) {
      return (
        <button
          className="button is-light"
          type="button"
          onClick={this.getLink}
        >
          Create a link
        </button>
      );
    }
  }

  // sidebar with group checkboxes and option to add/remove weekends
  renderSidebar = () => {
    if (this.state.checkedLocations) {
      //let groupColors = this.state.groupColors;
      let groups = [];
      let locations = [];
      let groupsKey = 0;
      let locationsKey = 0;
      for (const [group, info] of Object.entries(this.state.groupColors)) {
        groups.push(
          <div key={groupsKey} onClick={this.toggleGroup} style={{cursor: "pointer"}}>
            <span
              style={{
                backgroundColor: this.state.checkedList.includes(group)
                  ? info.color
                  : "#EDEDED",
              }}
              className="group-box"
            ></span>
            <p name={group}>&nbsp; {group}</p>
          </div>
        )

        groupsKey += 1;
      }

      for (const [loc, color] of Object.entries(this.state.locationColors)) {
        locations.push(
          <div key={locationsKey} onClick={this.toggleLocation} style={{cursor: "pointer"}}>
            <span
              style={{
                backgroundColor: this.state.checkedLocations.includes(loc)
                  ? color
                  : "#EDEDED",
              }}
              className="group-box"
            ></span>

            <p name={loc}>&nbsp; {loc}</p>
          </div>
        )
        locationsKey += 1;
      }

      return (
        <div>
          {/* differently colored check box for each group */}
          <h1 className="title is-5">Groups:</h1>
          {groups}
          <br />
          <h1 className="title is-5">Locations:</h1>
          <div className="control">{locations}</div>
          <br />
        </div>
      );
    }  
  };

  // add or remove a location from the calendar display
  toggleLocation = (event) => {
    let locations = this.state.checkedLocations;
    let location = event.target.getAttribute("name");

    // after unchecking a group, remove it from checkedList state
    if (locations.includes(location)) {
      let index = locations.findIndex((element) => element === location);
      locations.splice(index, 1);
    } else {
      locations.push(location);
      // this.calendarRef.current.getApi().refetchEvents()
    }
    this.setState({ checkedLocations: locations });
    this.updateBannerRows();
  };

  // add or remove a group from the calendar display
  toggleGroup = (event) => {
    let groups = this.state.checkedList;
    let group = event.target.getAttribute("name");
    let locations = [];

    // after unchecking a group, remove it from checkedList state
    if (groups.includes(group)) {
      let index = groups.findIndex((element) => element === group);
      groups.splice(index, 1);

      groups.forEach((grp) => {
        this.state.groupColors[grp].locations.forEach((loc) => {
          if (!locations.includes(loc) && this.state.checkedLocations.includes(loc)){
            locations.push(loc);
          }
        })
      })
    }
    // after checking a new group, add it to the checkedList state and refresh fullcalendar
    else {
      groups.push(group);
      locations = this.state.checkedLocations;
      this.state.groupColors[group].locations.forEach((loc) => {
        if (!locations.includes(loc)) {
          locations.push(loc);
        }
      })
    }

    this.setState({ checkedList: groups, checkedLocations: locations });
    this.updateBannerRows();
  };

  renderTimeSelector = () => {
    if (this.state.todayStart && this.state.todayEnd){
      let options = [];
      let midnight = new Date().setHours(0, 0, 0, 0);
      let startSelectorMax;
      let endSelectorMin;
      for (let i = 0; i < 27; i++) {
        let epoch = (i * 3600000) + midnight;
        options.push(<option value={epoch} key={i}>{format(new Date(epoch), "haaa")}</option>)
        if (epoch === this.state.todayStart){
          endSelectorMin = i;
        }
        if (epoch === this.state.todayEnd){
          startSelectorMax = i;
        }
      }
  
      return (
        <div className="time-selector-container">
          <h1 className="title is-5">Time:</h1>
          <div className="select is-small">
            <select onChange={this.updateTodayStart} value={this.state.todayStart}>
              {options.slice(0, startSelectorMax)}
            </select>
          </div>
          <p style={{display: "inline"}}> - </p>
          <div className="select is-small">
            <select onChange={this.updateTodayEnd} value={this.state.todayEnd}>
              {options.slice(endSelectorMin + 1)}
            </select>
          </div>
        </div>
      )
    }
  }

  updateTodayStart = (event) => { 
    this.setState({ todayStart: parseInt(event.target.value) }, () => {this.updateBannerRows()});
  }

  updateTodayEnd = (event) => {
    this.setState({ todayEnd: parseInt(event.target.value) }, () => {this.updateBannerRows()});
  }

  closeFlash = () => {
    this.setState({ error: false, success: false })
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  //show error message
  flash = () => {
    if (this.state.error) {
      return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
    }
    if (this.state.success) {
      return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
    }
  }

  render() {
    if (this.state.isIframe) {
      return (
        <div className="container">
          {this.drawBanner()}
        </div>
      )
    } else {
      return (
        <div>
          {this.flash()}
          <div className="full-calendar columns">
            {/* sidebar */}
            <div className="column is-2 sidebar">
              {this.renderSidebar()}
              {this.renderTimeSelector()}
              {this.linkButton()}
              {/* render button to create iframe link */}
            </div>
  
            <div className="column">
              {this.drawBanner()}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Banner;