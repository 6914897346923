import React from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import format from 'date-fns/format'
import axios from 'axios';
import addDays from 'date-fns/addDays'
import DeleteShiftsModal from './deleteShiftsModal'
import IncludeEmployeeModal from './includeEmployeesModal'



class DraftCalendar extends React.Component {

  constructor(props) {
    super();
    this.state = {
      autoGenBtn: "Auto Match Shifts",
      showDeleteModal: false,
      showIncludeEmployeeModal: false
    }
  }

  copyWeek = () => {
    let self = this;
    const firstDayEpoch = self.props.calendarRef.current.getApi().getDate().getTime()
    axios.get(`schedule/copyWeek/${firstDayEpoch}/${this.props.data.scheduleId}`).then((response) => {
      self.props.setFlash(false, ["Copied!"]);
    }).catch(function (err) {
      self.props.setFlash(["Error"], false);
      console.log(err)
    });
  }

  pasteWeek = () => {
    let self = this;
    const firstDayEpoch = self.props.calendarRef.current.getApi().getDate().getTime()

    axios.get(`schedule/pasteWeek/${firstDayEpoch}/${this.props.data.scheduleId}`).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.props.resetState();
      self.props.setFlash(false, ["Paste!"]);
    }).catch(function (err) {
      console.log(err)
      // self.setFlash(true, ["Error"]);
    });
  }

  deleteShifts = (users, openShifts, oneWeekOnly) => {
    let self = this;
    let formData = new FormData();
    let firstDayEpoch = null;
    if (oneWeekOnly === 'true') {
      firstDayEpoch = self.props.calendarRef.current.getApi().getDate().getTime()
    }
    formData.append("users", JSON.stringify(users));
    formData.append('openShifts', openShifts);
    formData.append('firstDayEpoch', firstDayEpoch);
    axios.post(`schedule/delete_shifts/${this.props.data.scheduleId}`,
      formData,
      { headers: { 'content-type': 'application/form-data' } }
    ).then((response) => {
      self.props.getSchedule(self.props.data.scheduleId);
      self.props.resetState();
      self.props.setFlash(null, ['Shifts have been deleted!']);
    }).catch(function (err) {
      console.log(err)
    });
  }

  showDeleteModal = () => {
    this.setState({ showDeleteModal: true })
  }

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false })
  }

  showIncludeEmployeeModal = () => {
    this.setState({ showIncludeEmployeeModal: true })
  }

  closeIncludeEmployeeModal = () => {
    this.setState({ showIncludeEmployeeModal: false })
  }

  // when event is clicked, delete shift (requires confirmation)
  handleEventClick = (eventInfo) => {
    let eventjs = this.handleEventHighlight(eventInfo);

    let getEvent = eventInfo.event;
    let extended = getEvent.extendedProps;
    let event = {
      edit: 'Save',
      start_time: getEvent.start,
      end_time: getEvent.end,
      location: extended.location,
      shiftId: extended.shiftId,
      status: extended.status,
      employee: extended.employee,
      group: extended.group,
      note: extended.note,
      success: false,
      error: false,
      style: eventjs.el.style
    }
    if (this.props.bulkSelect) {
      let selectedShiftIds = this.props.selectedShiftIds;
      let selectedShifts = this.props.selectedShifts;
      let findIndex = selectedShiftIds.indexOf(event.shiftId);
      if (findIndex !== -1) {
        eventjs = this.removeEventHighlight(eventInfo);
        selectedShiftIds.splice(findIndex, 1);
        selectedShifts.splice(findIndex, 1);
        event.style = eventjs.el.style;
      } else {
        selectedShiftIds.push(event.shiftId);
        selectedShifts.push(event);
      }
      // console.log("my events selects:", selectedShiftIds)
      this.props.setselectedShiftIds(selectedShiftIds, selectedShifts);
    } else {
      this.props.handleSetEvent(event);
    }
  }

  // when full calendar is updated (shift is added, updated, or removed), update the shifts in the Build component
  changeFullCalendar = (e) => {
    // console.log(e);
    if (!this.props.checkError(e.event.start, e.event.end)) {
      let newObj = {} //full calendar sets the event as write only, so we create our own obj
      const newStart = e.event.start / 1000;
      const newEnd = e.event.end / 1000;
      let shiftInfo = e.event.extendedProps
      for (const [key, value] of Object.entries(shiftInfo)) {
        newObj[key] = value
      }
      newObj.start_time = newStart;
      newObj.end_time = newEnd;
      // console.log(newObj);
      this.props.checkAvailability(e, newObj);
    }
  }


  drawCalendar() {
    const self = this;
    return (
      <div className="card">
        <div className="card-content">
          <FullCalendar
            ref={this.props.calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            headerToolbar={{ // calendar header with navigation options
              left: 'today prev,next',
              center: 'title',
              right: 'timeGridWeek,dayGridDay,listWeek'
            }}
            loading={function (bool) {
              if (bool) {
                //  console.log('I am populating the calendar with events');
              }
              else {
                self.props.showFlash();
                // bind to all your events here
              }
            }}
            unselectAuto={false}
            initialView="timeGridWeek" // show week as initial calendar
            height={700} // TODO: reasonable responsive height
            showNonCurrentDates={false} // month calendar grays out days not in specified time period
            allDaySlot={false} // no all day events
            nowIndicator={true} // red line and arrow to indicate current time
            scrollTimeReset={false}
            scrollTime={'08:00:00'} // week/day view begins at 9 am
            editable={this.props.bulkSelect ? false : true} // allows shift modification w/ clicking and dragging
            selectable={true} // select time slot by clicking and dragging
            select={this.props.handleDateSelect} // create shift after selecting time slot
            eventClick={this.handleEventClick} // delete shift after clicking on it
            validRange={{ // user can only move around calendar in time range specified in form (from 'Generate a Schedule')
              start: this.props.calendarStartDate,
              end: this.props.calendarEndDate
            }}
            eventAdd={this.changeFullCalendar} // triggers when event is added to calendar: updates shifts in Build component
            eventChange={this.changeFullCalendar} // triggers when event is updated: updates shifts in Build component
            eventRemove={this.changeFullCalendar} // triggers when event is removed from calendar: updates shifts in Build component
            eventContent={this.renderEventContent.bind(this)}   // overrides fullcalendar's event display
            eventBorderColor={"whitesmoke"}
            initialEvents={this.getEventInfo} // (needs to be a function so we can use refetchEvents call to update)
          />
        </div>
      </div >
    )
  }

  //get initial events
  getEventInfo = (info, successCallback, failureCallback) => {
    let self = this;
    if (self.props.data.shifts) {
      // console.log("info?")
      // console.log(info)
      // console.log("LOGGING SHIFTS");
      // console.log(self.props.data.shifts)
      self.props.data.shifts.forEach((shift, index) => {
        shift['start'] = shift['start_time'] * 1000;
        shift['end'] = shift['end_time'] * 1000;
      }
      )
      successCallback(self.props.data.shifts) //TODO format these cells :)
    } else {
      // console.log('no shifts');
      successCallback([]) //if no shifts, draw nothing
    }
  }

  handleEventHighlight = (eventInfo) => {
    //make sure you get the same element every click, otherwise you get that partial highlight
    let event = eventInfo.jsEvent
    event.preventDefault();
    let eventDiv = event.target.parentElement;
    if (eventDiv.classList.contains("event")) {
      eventDiv = eventDiv.parentElement;
    } else if (eventDiv.classList.contains("fc-timegrid-event")) {
      eventDiv = eventDiv.firstElementChild
    }

    if (!this.props.bulkSelect) {
      const highlightedEvents = document.getElementsByClassName('selected-event') //should only be one :)
      console.log("THE EVENT")
      console.log(eventDiv)
      for (let i = 0; i < highlightedEvents.length; i++) {
        highlightedEvents[i].classList.remove('selected-event');
      }
    }
    eventDiv.classList.add('selected-event');
    return eventInfo;
  }

  removeEventHighlight = (eventInfo) => {
    //make sure you get the same element every click, otherwise you get that partial highlight
    let event = eventInfo.jsEvent
    event.preventDefault();
    let eventDiv = event.target.parentElement;
    if (eventDiv.classList.contains("event")) {
      eventDiv = eventDiv.parentElement;
    } else if (eventDiv.classList.contains("fc-timegrid-event")) {
      eventDiv = eventDiv.firstElementChild
    }

    eventDiv.classList.remove('selected-event');
    return eventInfo;
  }

  // overrides fullcalendar's event display
  renderEventContent(eventInfo) {
    let divStyle, highlight;
    let employee = eventInfo.event.extendedProps.employee;
    const name = employee ? (employee.display_name || employee.name) : "Available";
    const netid = employee ? employee.netid : "Available";
    if (this.props.highlightedEmployee !== "--Select--" && netid !== this.props.highlightedEmployee) {
      highlight = { opacity: 0.3 }
      if (!eventInfo.event.extendedProps.published) {
        eventInfo.backgroundColor = "lightGrey";
      } else {
        eventInfo.backgroundColor = "lightSkyBlue";
      }
    } else {
      highlight = { opacity: 1.0 }
      if (!eventInfo.event.extendedProps.published) {
        eventInfo.backgroundColor = "grey";
      }
    }
    if (this.props.colorCodes)
      divStyle = {
        color: this.props.colorCodes[eventInfo.event.extendedProps.location]
      }
    return (
      <div ref={this.myRef} className="event" style={highlight}>
        {/* bolded time range: start-end  */}
        <p className="bold lower">
          <FontAwesomeIcon style={divStyle} icon={faCircle} />
          {format((eventInfo.event.start), "h:mma")}&#8211;{format((eventInfo.event.end ? eventInfo.event.end : eventInfo.event.start), "h:mma")}
        </p>

        <p>
          {name} | {eventInfo.event.extendedProps.location}
        </p>
      </div>
    )
  }

  autoMatchShifts = (includeEmployees) => {
    this.setState({ autoGenBtn: "Matching in progress...", showIncludeEmployeeModal: false })
    let self = this;
    axios.put("schedule/autogen/" + this.props.data.scheduleId, includeEmployees).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.setState({ autoGenBtn: "Auto Match Shifts" })
    }).catch(function (err) {
      self.props.setFlash(["There was an unexpected error matching your shifts."], false);
      self.props.setState({ autoGenBtn: "Auto Match Shifts" })
      console.log(err)
    });
  }

  publishShifts = () => {
    let self = this;
    axios.get("schedule/publish/" + self.props.data.scheduleId).then((response) => {
      // console.log(self.calendarRef.current.getApi())
      // self.calendarRef.current.getApi().refetchEvents() // refresh fullcalendar
      self.props.getSchedule(this.props.data.scheduleId);
      self.props.resetState();
      this.props.setFlash(null, ['Your shifts have been published!']);
    }).catch(function (err) {
      console.log(err)
    });
  }

  unPublishShifts = () => {
    let self = this;
    axios.get("schedule/unpublish/" + self.props.data.scheduleId).then((response) => {
      self.props.getSchedule(this.props.data.scheduleId);
      self.props.resetState();
      this.props.setFlash(null, ['Your shift has been unpublished!']);
    }).catch(function (err) {
      console.log(err)
    });
  }

  handleBulkDelete = () => {
    const self = this;
    axios.put("/shifts/bulkDelete/", { shifts: this.props.selectedShiftIds })
      .then((response) => {
        self.props.getSchedule(this.props.data.scheduleId);
        self.props.resetState();
        self.props.removeAllEventHighlight();
        self.props.setFlash(null, ['Your shifts have been deleted!']);
      }).catch(function (err) {
        console.log(err)
      });
  }

  handleCancelBulkDelete = async () => {
    this.props.resetState();
    await this.props.removeAllEventHighlight();
  }

  handleBulkSelect = () => {
    this.props.setBulkSelect();
    this.props.removeAllEventHighlight();
  }

  renderButtons = () => {
    // if (this.props.data.scheduleType === "review") {
    return (
      <div className="card">
        <div className="card-content">
          {this.props.bulkSelect ?
            <div className="buttons is-grouped">
              <input className="button" type='button' value="Delete Shifts" onClick={this.handleBulkDelete} />
              <input className="button" type='button' value="Cancel Action" onClick={this.handleCancelBulkDelete} />
            </div> :
            <div className="buttons is-grouped">
              <input className="button" type='button' value={this.state.autoGenBtn} onClick={this.showIncludeEmployeeModal} />
              <input className="button" type='button' value="Publish Shifts" onClick={this.publishShifts} />
              <input className="button" type='button' value="Unpublish Shifts" onClick={this.unPublishShifts} />
              <input className="button" type='button' value="Copy Week" onClick={this.copyWeek} />
              <input className="button" type='button' value="Paste Week" onClick={this.pasteWeek} />
              <input className="button" type='button' value="Delete Shifts" onClick={this.showDeleteModal} />
              <input className="button" type='button' value="Select and Delete Shifts" onClick={this.handleBulkSelect} />
            </div>}
        </div>
      </div>
    )
    // }
  }

  //reset the states
  resetState = () => {
    this.setState({
      start_time: null,
      end_time: null,
      days_of_week: null,
      edit: 'Add',
      employee: null,
      note: ''
    })
  }



  render() {
    return <div className="column is-9">
      {this.drawCalendar()}
      {this.renderButtons()}
      {this.state.showDeleteModal ?
        <DeleteShiftsModal
          closeModal={this.closeDeleteModal}
          deleteShifts={this.deleteShifts}
          users={this.props.data.users}
          startWeek={this.props.calendarRef.current.getApi().view.activeStart}
          endWeek={addDays(this.props.calendarRef.current.getApi().view.activeEnd, -1)}
        /> : null}
      {this.state.showIncludeEmployeeModal ?
        <IncludeEmployeeModal
          closeModal={this.closeIncludeEmployeeModal}
          autoMatchShifts={this.autoMatchShifts}
          group={this.props.data.group}
        /> : null}
    </div>
  }
}

export default DraftCalendar;