import React, { Component } from "react";

const types = {
    textReminder: {
        title: 'Text Reminder',
        messages: ["If students register their phone numbers, they will receive text reminders of their shifts.",
        "The schedule belows allows you to customize when the reminder messages should go out."]
    },
    timecardReminder: {
        title: 'Timecard Reminder',
        messages: ["If students register their phone numbers, they will receive timecard reminders.",
        "The schedule belows allows you to customize when the timecard messages should go out."]
    },
    IP: {
        title: 'IP Ranges',
        messages: ["Once it's set, students cannot clock in/out outside the range.", 
        "There can be multiple ip ranges.", "Can be set in the Manage Group Tab."]
    },
    grouper: {
        title: 'Grouper Path',
        messages: ["This is the urn: address for your group from grouper", 
        "Once this is set, you should be able to view your employees in the Employee Tab", 
        "Don't forget to click Grouper sync", "You can edit your group on grouper.oit.duke.edu."]
    },
    teamsURL: {
        title: 'Teams URL',
        messages: ["You can set up a specifically teams channel to received clock in/out, shift drop notification.", 
        "Go to the channel setting and add a connector.", "Add Incoming Webhook and configure a new webhook.", 
        "Copy the new webhook link into shiftr and be ready to receive notifications!", "Please contact Anni for questions :)"]
    },
    weeklyLimit: {
        title: 'Weekly Hour limit',
        messages: ["This setting limits the amount of shifts a student can claim from the open shift pool.", 
        "It does NOT affect automatch, clock in/out or self report time.", "When it's set to 0, it does not set a limit."]
    },
    shiftCoverage: {
        title: 'Shift Coverage',
        messages: ["This setting will require students to find coverage for the shifts they offer up.", 
        "As a supervisor, you can release student from a shift.", "Other students can also pick up offered up shifts."]
    }
}

export default class Modal extends Component {
    render() {
        let title = ''
        let messages = ''
        if (this.props.type) {
            title = types[this.props.type].title;
            messages = types[this.props.type].messages;
        }
        return <div id={this.props.type ? "modal-root visible" : "modal-root"} onClick={this.props.clockModal}>
        <div className={this.props.type ? "modal is-active" : "modal"}>
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">What is {title}?</p>
                    <button className="delete" aria-label="close" onClick={this.props.clockModal}></button>
                </header>
                <section className="modal-card-body">
                    {messages? <ul className="modal-ul">{messages.map((m, index) => <li key={index}>{m}</li>)}</ul>:''}
                </section>
            </div>
        </div>
        </div>
    }
}