import React, { Component } from 'react';

const DOTS = "...";
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { elements: this.props.elements, currentPage: null, totalPageCount: null, pageSize: null, renderElements: null, pageContents: [], nav: null };
  }

  componentDidMount = () => {
    let pageSize = this.props.pageSize;
    if(pageSize<4) {
      pageSize = 4;
    }

    this.setState({elements: this.props.elements, pageSize: pageSize});
    this.setState({currentPage: this.props.currentPage, totalPageCount: Math.ceil(this.props.totalCount/pageSize), renderElements: this.props.render() }, this.renderPageContents);
  }

  componentDidUpdate(prevProps) {
    let changedProps = prevProps.elements !== this.props.elements
    if (changedProps) {
        this.setState({ elements: this.props.elements, renderElements: this.props.render() }, this.renderPageContents);
    }
}

  renderPageContents = () => {
    let pageSize = this.state.pageSize;

    let contents;
    if ((this.state.currentPage)*(pageSize) < this.props.totalCount) {
        contents = this.state.renderElements.slice((this.state.currentPage-1)*(pageSize), (this.state.currentPage)*(pageSize));
    } else {
        contents = this.state.renderElements.slice((this.state.currentPage-1)*(pageSize), this.props.totalCount);
    }
    this.setState({pageContents: contents}, this.renderPaginationNav());
  }

  onPageChange = (event) => {
    // let newPage = event.value;
    // console.log("PAGE:  ", event.target.value);
    let page = parseInt(event.target.value);
    if(page >0 && page <= this.state.totalPageCount) {
        // console.log("PAGE CHANGE");
        this.setState({currentPage: page}, this.renderPageContents);
    }
  }

  range = (start, end) => {
    let length = end - start + 1;
    /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
    */
    return Array.from({length}, (_, idx) => idx + start);
  }

  paginationRange = () => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    let totalPageNumbers = this.props.siblingCount + 5;
    let totalPageCount = this.state.totalPageCount;
    let siblingCount = this.props.siblingCount;
    let currentPage = this.state.currentPage;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (this.state.totalPageCount <= totalPageNumbers) {
        return this.range(1, this.state.totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    let leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    let rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    let shouldShowLeftDots = leftSiblingIndex > 2;
    let shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    let firstPageIndex = 1;
    let lastPageIndex = totalPageCount;

     /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
        let leftItemCount = 3 + 2 * siblingCount;
        let leftRange = this.range(1, leftItemCount);

        return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
    
        let rightItemCount = 3 + 2 * siblingCount;
        let rightRange = this.range(
            totalPageCount - rightItemCount + 1,
            totalPageCount
        );
        return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
        let middleRange = this.range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }

  renderPaginationNav = () => {
    let pageRange = this.paginationRange();
    if (pageRange) {
        let nav = [];
        pageRange.forEach((page, index) => {
            if(page === this.state.currentPage) {
                nav.push(
                    <li key={index} style={{textAlign: 'center'}}>
                        <button className="button pagination-link is-current" value={page} aria-label={`Page ${page}`} aria-current="page" onClick={this.onPageChange}>{this.state.currentPage}</button>
                    </li>
                )
            } else if(page=== DOTS) {nav.push(
                <li key={index} style={{textAlign: 'center'}}>
                    <button className="pagination-ellipsis">&hellip;</button>
                </li>
            )}
            else { nav.push(
                <li key={index} style={{textAlign: 'center'}}>
                    <button className="button pagination-link" value={page} aria-label={`Goto page ${page}`} onClick={this.onPageChange}>{page}</button>
                </li>
            )}
        });
        // console.log("NAV: ", nav)
        this.setState({nav: nav}, function() { /*console.log("setState completed: ", this.state);*/ window.scrollTo(0, 0); });
    } 
  }

  render() {

    return (
      <div style={{display: 'block'}}>
        {this.state.pageContents}
        <nav className="pagination-is-rouned" role="navigation" aria-label="pagination" style={{textAlign: 'center', margin: 'auto'}}>
            {/* <button className="button pagination-previous" onClick={this.onPageChange} value={this.state.currentPage-1}>Previous</button> */}
            {/* <button className="button pagination-next" onClick={this.onPageChange} value={this.state.currentPage+1}>Next page</button> */}
            <ul className="pagination-list" style={{justifyContent: 'center'}}>
                <li><button className="button pagination-previous" onClick={this.onPageChange} value={this.state.currentPage-1}>&#8592;</button></li>
                {this.state.nav}
                <li><button className="button pagination-next" onClick={this.onPageChange} value={this.state.currentPage+1}>&#8594;</button></li>
            </ul>
        </nav>
      </div>
    );
  }
}

// function PageBtn(props) {
//     const type = props.type ? props.type : "button"
//     return <div className="control">
//       <button className={props.className} type={type} onClick={props.onClick} disabled={props.isDisabled}>{props.value}</button>
//     </div>
//   }

export default Pagination;