import React, { Component } from 'react';
import axios from 'axios';
import Flash from '../common/flash';

class IncludeEmployeeModal extends Component {
    constructor(props) {
        super();
        this.state = { employees: null, employeeNames: [], openShifts: false, oneWeekOnly: null, error: null };
    }

    componentDidMount() {
        this.getEmployees();
    }

    getEmployees = () => {
        let self = this;
        axios.get("users/employee_list/" + this.props.group).then((response) => {
            let employees = Array.from({ length: response.data.length }).map(x => true);
            this.setState({ employees });
            self.setState({ employeeNames: response.data });
        }).catch((error) => {
            console.log(error)
        });
    }

    handleEmployeesChange = (event, duid) => {
        let index = this.state.employeeNames.findIndex(element => {
            return element.duid === duid;
        });
       
        let employees = this.state.employees;
        employees[index] = !employees[index];
        this.setState({ employees: employees });
    }

    handleOpenShiftsChange = () => {
        this.setState({ openShifts: !this.state.openShifts });
    }

    handleRadioChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }

    checkAll = () => {
        this.setState({ openShifts: true });
        this.setState({ employees: Array.from({ length: this.state.employeeNames.length }).map(x => true) });
    }

    uncheckAll = () => {
        this.setState({ openShifts: false });
        this.setState({ employees: Array.from({ length: this.state.employeeNames.length }).map(x => false) });
    }

    employeesCheckboxes = () => {
        if (this.state.employees) {
            let checkboxes = [];
            let employees = this.state.employees;
            this.state.employeeNames.forEach((user, index) => {
            //   console.log("USER", user)
                checkboxes.push(
                    <div key={user.display_name}>
                        <label className="checkbox">
                            <input
                                name={user.display_name}
                                type="checkbox"
                                checked={employees[index]}
                                onChange={(e) => this.handleEmployeesChange(e, user.duid)}
                            />
                            &nbsp;
                            {user.display_name}
                        </label>
                    </div>)
            });
            return (
                <div className="field">
                    <div className="control">
                        {checkboxes}
                    </div>
                </div>
            )
        }
    }

    flash = () => {
        if (this.state.error) {
            return <div className="mb-2">
                <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
            </div>
        }
    }

    closeFlash = () => {
        this.setState({ error: null })
    }

    handleMatch = () => {
        let excludes = this.state.employeeNames.filter((d, index) => this.state.employees[index] ? d : false)
        this.props.autoMatchShifts(excludes);
    }

    render() {
        return (
            <div className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Include the following employees:</p>
                        <button onClick={this.props.closeModal} className="delete" aria-label="close"></button>
                    </header>
                    <section className="modal-card-body">
                        {this.flash()}
                        <form>
                            <label className="label mb-1">Employees:</label>
                            <div className="tile">
                                <p className="is-clickable" onClick={this.checkAll}>All</p>
                                &nbsp;|&nbsp;
                                <p className="is-clickable" onClick={this.uncheckAll}>None</p>
                            </div>

                            {this.employeesCheckboxes()}

                            <br />

                            <button
                                className="button is-danger"
                                type="button"
                                onClick={this.handleMatch}
                            >
                                Auto Match
                            </button>
                        </form>
                    </section>
                </div>
            </div>
        )
    }
}

export default IncludeEmployeeModal
