import React, { Component } from 'react';
import Week from './weeklyAvailability';
import ProfileInfo from './profileInfo';
import axios from 'axios';
import Flash from '../common/flash';

import './index.css';

class ProfileIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      render: 'profile',
      netid: '',
      data: null,
      isUnavailability: "Weekly Availability"
    };
  }

  componentDidMount() {
    axios.get("workGroups/find/" + localStorage.getItem('group').split(",")[0])
      .then(response => {
        this.setState({ isUnavailability: response.data.isUnavailability ? "Weekly Unavailability" : "Weekly Availability" })
      }).catch((error) => {
        console.log(error)
      });
  }

  //change the render tab name
  handleClick(compName, e) {
    this.setState({ render: compName });
  }

  //get use's availability info from db
  getWeeklyAvailability = () => {
    axios.get("users/get_weekly_availability/").then((response) => {
      this.setState({ data: response.data });
      // console.log(this.state.data);
    }).catch((error) => {
      console.log(error)
    });
  }

  setFlash = (error, success) => {
    this.setState({ error: error, success: success });
  }

  closeFlash = () => {
    this.setState({ error: false, success: false })
  }

  //show error message
  flash = () => {
    if (this.state.error) {
      return <Flash type="error" title="Incomplete Form" messages={this.state.error} closeFlash={this.closeFlash} />
    }
    if (this.state.success) {
      return <Flash type="success" title="Success" messages={this.state.success} closeFlash={this.closeFlash} />
    }
  }

  //rerender the pagae
  switchTab() {
    switch (this.state.render) {
      default: return <ProfileInfo setFlash={this.setFlash} />
      case 'week': return <Week data={this.state.data} isUnavailability={this.state.isUnavailability} getData={this.getWeeklyAvailability} setFlash={this.setFlash} />
    }
  }

  render() {
    return (
      <div className="container">
        <div className="tabs">
          <ul>
            <li className={this.state.render === 'profile' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'profile')}><a href="#/">Profile</a></li>
            <li className={this.state.render === 'week' ? 'is-active' : null} onClick={this.handleClick.bind(this, 'week')}><a href="#/">{this.state.isUnavailability}</a></li>
          </ul>
        </div>
        {this.flash()}
        {this.switchTab()}
      </div>

    )
  }
}

export default ProfileIndex;
