import React, { Component } from 'react';

class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.state = { model: false }
  }

  showTime = (days, timeSlots) => {
    for (let day in days) {
      if (days[day]) {
        days[day].forEach((timeSlot, index) => {
          if (timeSlot.prettyStart && timeSlot.prettyEnd) {
            timeSlots.push(<li key={day + index + timeSlot.start}> {day}: {timeSlot.prettyStart} - {timeSlot.prettyEnd}</li>)
          }
        })
      }
    }
  }

  availabilityTable = () => {
    let timeSlots = []
    // console.log(this.props.user)
    if (this.props.user.weekly_availability && this.props.user.weekly_availability[0]) {
      timeSlots.push(<span key="preferred">Preferred Time:</span>);
      this.showTime(this.props.user.weekly_availability[0], timeSlots);
    }
    if (this.props.user.weekly_maybe && this.props.user.weekly_maybe[0]) {
      timeSlots.push(<span key="available">Available Time:</span>);
      this.showTime(this.props.user.weekly_maybe[0], timeSlots);
    }
    if (timeSlots.length < 2) {
      timeSlots.push(<li key="None">No availability</li>)
    }
    return timeSlots;
  }

  showModal = () => {
    this.setState({ modal: true });
  }

  hideModal = () => {
    this.setState({ modal: false });
  }

  formModal = () => {
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Availability</p>
            <button onClick={this.props.closeAvalability} className="delete" aria-label="close"></button>
          </header>
          <section className="modal-card-body">
            <ul>
              {this.availabilityTable()}
            </ul>
          </section>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.formModal()}
      </div>
    );
  }
}

export default AvailabilityModal