import React, { Component } from "react";
import { PDFViewer, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import withRouter from './withRouter';
import axios from 'axios';
import Spinner from "./loader";
import WeeklyHourTable from "../hours&reports/hours/weeklyHourTable";
import format from "date-fns/format";

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    container: {
        padding: 30,
    },
    list: {
        marginBottom: 10,
        display: 'block'
    },
    subtitle: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 15
    },
    username: {
        fontSize: 15,
        marginTop: 5,
        marginBottom: 5
    },
    noshifts: {
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5
    },
    title: {
        fontSize: 24,
        textTransform: 'uppercase',
    },
});

class ShiftrDocument extends Component {
    constructor(props) {
        super();
        this.state = {
            users: []
        }
    }
    componentDidMount = () => {
        this.getGroupShifts();
    }
    sortTime = (data) => {
        return data.shifts.sort((a, b) => {
            if (a.start_time < b.start_time || (a.start_time === b.start_time && a.end_time < b.end_time))
                return -1;
            if (a.start_time > b.start_time || (a.start_time === b.start_time && a.end_time > b.end_time))
                return 1;
            return 0;
        });
    }

    getGroupShifts = () => {
        let self = this;
        let group = this.props.params.groups;
        let start = this.props.params.start;
        let end = this.props.params.end;
        if (localStorage.getItem('role') === 'supervisor' || localStorage.getItem('role') === 'admin') {
            // this route only returns hours if the employee had clocked in/out
            axios.get(`shifts/supervisor_hours/${start}/${end}/${group}`).then((response) => {
                response.data.map(employee => this.sortTime(employee.details));
                self.setState({ users: response.data })
                console.log(response.data[32])
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    checkHour = (total, complete) => {
        if (total < complete) { return 0 }
        return Math.round((total - complete) / 360) / 10
    }

    mapShifts = () => {
        let start = this.props.params.start;
        let end = this.props.params.end;
        if (this.state.users && start && end) {
            console.log(this.state.users[0])
            return this.state.users.map((user, index) => {
                let row;
                if (user.display_name && user.role !== 'admin') {
                    row = <View style={styles.list} key={index}>
                        <Text style={styles.username}> {user.display_name || user.name}({user.netid})</Text>
                        {user.details.shifts ? <WeeklyHourTable getShifts={this.getGroupShifts}
                            startDate={new Date(start)} endDate={new Date(end)} selectedEmployee={user} pdf={true} />:
                            <Text style={styles.noshifts}>This employee did not clock hours.</Text>}
                        <br />
                    </View>
                }
                return row;
            })
        }
    }
    render() {
        console.log('Props:', this.props.params)
        let start = format(new Date(this.props.params.start), "MMM-dd hh:mma");
        let end = format(new Date(this.props.params.end), "MMM-dd hh:mma");
        return (
            <>{!this.state.users ? <Spinner /> :
                <PDFViewer id="pdf-viewer">
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <View style={styles.container}>
                                <Text style={styles.title}>Employee Work Hours</Text>
                                <Text style={styles.subtitle}>from {start} to {end}</Text>
                                <br />
                                {this.mapShifts()}
                            </View>
                        </Page>
                    </Document>
                </PDFViewer>
            }</>
        )
    }
}
export default withRouter(ShiftrDocument);