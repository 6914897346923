import axios from 'axios';

export const checkGroups = async () => {
    let groups = localStorage.getItem('group').split(",");
    let groupsData = [];
    let nullGroups = [];

    for (const group of groups) {
        if (group) {
            let response = await axios.get("workGroups/find/" + group);
            if (response.data) { groupsData.push(response.data); }
            else {
                nullGroups.push(group);
            }
        } else {
            await axios.put("users/removeNullGroup");
        }
    }
    if (nullGroups.length > 0) {
        // let str = nullGroups.toString();
        let error = ("Cannot load group(s): " + nullGroups.toString() + ". Group name(s) may have been changed. You need to log out and log back in.");
        console.log("checkGroups: ", error)
        return { error: ("SYS!:" + error) };
    } else {
        return { error: false }
    }
}