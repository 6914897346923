import React, { Component } from "react";
import { Link } from "react-router-dom";

class Nbar extends Component {
  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);
    this.state = { active: false };
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  }

  render() {
    let manageSchedule;
    let manageGroups;
    let employees;
    let adminpage;
    let additionalNav;
    let bannerpage;
    if (
      localStorage.getItem("role") === "supervisor" ||
      localStorage.getItem("role") === "admin"
    ) {
      employees = <Link to="/employees" className="navbar-item nav-link">Employees</Link>
      manageSchedule = <Link to="manage_schedule" className="navbar-item nav-link">Manage Schedule</Link>
      manageGroups = <Link to="manage_groups" className="navbar-item nav-link">Manage Groups</Link>
      bannerpage = <Link to="/banner" className="navbar-item nav-link">Banners</Link>
    }
    if (localStorage.getItem("role") === "admin") {
      adminpage = <Link to="/admin" className="navbar-item nav-link">Admin</Link>
    }

    if (localStorage.getItem("role") !== "guest") {
      additionalNav = (
        <>
          <Link to="/" className="navbar-item nav-link">Home</Link>
          <Link to="/schedule" className="navbar-item nav-link">Schedule</Link>
          {bannerpage}
          {manageSchedule}
          {employees}
          {manageGroups}
          <Link to="/hours_and_reports" className="navbar-item nav-link">Hours/Reports</Link>
          <Link to="/profile" className="navbar-item nav-link">Profile</Link>
          <Link to="/shiftr_docs" className="navbar-item nav-link" target="_blank" rel="noopener noreferrer" >Shiftr Docs</Link>
          {adminpage}
        </>
      );
    }
    return (
      <div>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <p className="logo">
              <Link to="/">Shiftr</Link>
            </p>
            <div
              role="button"
              className={
                this.state.active ? "navbar-burger is-active" : "navbar-burger"
              }
              onClick={this.toggleClass}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbar"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div
            id="navbar"
            className={
              this.state.active ? "navbar-menu is-active" : "navbar-menu"
            }
            onClick={this.toggleClass}
          >
            <div className="navbar-end">
              {additionalNav}
              <div className="navbar-item nav-link" onClick={this.props.logout}>
                Logout
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default Nbar;
