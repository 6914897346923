import React, {Component} from 'react';

export default class Home extends Component {

  //clear local storage
  //clear cookie
  //move to shiftr login page

  samlLogin = () => {
    let url = `https://shib.oit.duke.edu/idp/profile/SAML2/Unsolicited/SSO?providerId=https://shiftr.colab.duke.edu&shire=${process.env.REACT_APP_SAML_ACS}`
    window.location.href = url;
  }

  render(){
    return (
      <div>
        <div key="login" className="login-background">
          <div className="login-container">
            <h1 className="login_header">WELCOME TO SHIFTR!</h1>
            <button className="button login-button" onClick={this.samlLogin}>LOGIN</button>
          </div>
        </div>
      </div>
    );
  }
}
