import React, { Component } from 'react';
import Pagination from "../common/pagination";
import Pool from '../../assets/pool.png';
import format from "date-fns/format";
import axios from 'axios';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import getUnixTime from 'date-fns/getUnixTime';

export default class OpenshiftPool extends Component {
  constructor(props) {
    super(props);
    this.state = { pageSize: null, }
  }

  releaseShift = (shift) => {
    axios.get("shifts/release/" + shift._id)
      .then((response) => {
        this.props.refreshShifts();
        this.props.logAction("release a shift");
      }).catch((error) => {
        console.log(error);
      });
  }

  getShiftsByWeek = async (start_time) => {
    let startTime = getUnixTime(startOfWeek(start_time * 1000));
    let endTime = getUnixTime(endOfWeek(start_time * 1000));
    return await axios.get("shifts/find_by_time_and_user/" + startTime + "/" + endTime).then((response) => {
      let hourCount = 0;
      response.data.map(s => hourCount += s.end_time - s.start_time);
      return hourCount / 3600;
    }).catch((error) => {
      console.log(error)
    });
  }

  claimShift = (shift) => {
    axios.get("shifts/claim/" + shift._id)
      .then(() => {
        this.props.getShiftsToday();
        this.props.getOpenShifts();
        this.props.getUpcomingShifts();
        this.props.logAction("claim a shift");
      }).catch((error) => {
        console.log(error);
      });
  }

  checkClaimShift = (shift) => {
    if (window.confirm('Are you sure you want to claim this shift?')) {
      axios.get("shifts/find_conflict/" + shift.start_time + "/" + shift.end_time).then((response) => {
        // console.log("DEBUG THIS")
        // console.log(response.data)
        // console.log("END DEBUG")
        if (response.data.length > 0) {
          this.props.setFlash("This shift conflicts with another shift in your schedule! You cannot claim this shift.", false);
          // } else if (response.data.length > 0 && shift.hasOwnProperty('employee') && shift.employee.netid !== localStorage.getItem('netid')) {
        }
        else {
          let group = this.props.groups.find(g => g.name === shift.group);
          if (group?.maxShiftHours) {
            this.getShiftsByWeek(shift.start_time).then(response => {
              let hoursCount = response;
              if (!group.maxShiftHours || parseInt(group?.maxShiftHours) <= 0 || hoursCount < parseInt(group?.maxShiftHours)) {
                this.claimShift(shift);
              } else {
                this.props.setFlash(`Warning:You already have ${hoursCount} hours of shifts this week. Please contact your manager.`, false);
              }
            })
          } else {
            this.claimShift(shift);
          }
        }
      });
    }
  }

  mapOpenShifts = () => {
    let dateFormat = "eee dd MMM";
    let timeFormat = "hh:mmaaaa";
    let shifts = this.props.openShifts;
    if (this.state.elements) {
      shifts = this.state.elements;
    }

    return shifts.map((shift, index) => {
      let info;
      let person;
      let releaseBtn;
      let claimBtn;
      if (localStorage.getItem('role') === "admin" || localStorage.getItem('role') === "supervisor") {
        releaseBtn = <button className="button is-info" onClick={this.releaseShift.bind(this, shift)}>Release Shift</button>
      }
      if (!shift.employee || localStorage.getItem('netid') !== shift.employee.netid) {
        claimBtn = <button className="button is-info" onClick={this.checkClaimShift.bind(this, shift)}>Claim</button>
      }

      //this is the offer up version
      if (shift.employee) {
        person = shift.employee.display_name.split(" ")[0] + " " + shift.employee.display_name.split(" ")[1].charAt(0) + ".";
        info = (
          <div>
            <article className="message is-warning">
              <div className="message-body">
                <p className='upcoming-shift-time'>{format(shift.start_time * 1000, timeFormat)} - {format(shift.end_time * 1000, timeFormat)}</p>
                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Covering for:</span> {person}</p>
                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Location:</span> {shift.location}</p>
                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Group:</span> {shift.group} </p>
                <div className="buttons">
                  {claimBtn}
                  {releaseBtn}
                </div>
              </div>
            </article>

          </div>
        );
      }
      //this is the open version
      if (info === undefined) {
        info = (
          <div>
            <article className="message is-dark">
              <div className="message-body">
                <p className='upcoming-shift-time'>{format(shift.start_time * 1000, timeFormat)} - {format(shift.end_time * 1000, timeFormat)}</p>
                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Location:</span> {shift.location}</p>
                <p className='upcoming-shift-text'><span className='upcoming-shift-time'>Group:</span> {shift.group} </p>
                {claimBtn}
              </div>
            </article>

          </div>
        );
      }


      return (
        <div key={index} className="shift-block">
          {/* shift pool column */}
          <div className='shift-date'>
            <p>{format(shift.start_time * 1000, dateFormat)}</p>
          </div>
          {info}

        </div>
      );
    });
  }

  drawOpenShifts = () => {
    if (this.props.openShifts.length > 0 && this.props.noUpcoming !== null) {
      let shiftPool = [<div className="section-title-group" key="title">
        <img src={Pool} className='shift-pool-image' alt="Pool" align="left" />
        {/* <p className="shift-pool-title">Shift Pool</p> */}
        <h2 className="section-title">Shift Pool</h2>
      </div>];

      let currentPage = 1;
      let pageSize = 4;
      if (this.state.pageSize) { pageSize = this.state.pageSize; }
      let siblingCount = 1;

      return (
        <div>
          <div>
            <div>
              {shiftPool}
            </div>
            <Pagination elements={this.props.openShifts} render={this.mapOpenShifts} totalCount={this.props.openShifts.length} currentPage={currentPage} pageSize={pageSize} siblingCount={siblingCount} onPageChange={null} />
          </div>
        </div>
      );
    }
  }

  render() {
    return <div key="pool" className='home-section'>
      <div>
        <div>
          <div>
            {this.drawOpenShifts()}
          </div>
        </div>
      </div>
    </div>
  }
}